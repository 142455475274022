import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router";
import axios from "axios";
import fileDownload from "js-file-download";

export const UserProject = () => {
  const params = useParams();
  const [grant, setGrant] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getProjectData();
  }, [params.id]);

  const getProjectData = async () => {
    axios
      .get(`/api/grant//get_grant/${params.id}`, {
        headers: { ["Content-Type"]: "application/json" },
      })
      .then(function (response) {
        setGrant(response.data[0]);
        setLoading(!loading);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const downloadProjectHandler = () => {
    axios
      .get(`/api/file/download/${grant.project_description}`, {
        responseType: "blob",
      })
      .then(function (response) {
        console.log(response);
        fileDownload(response.data, grant.project_description);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const downloadDiplomaHandler = () => {
    axios
      .get(`/api/file/download/${grant.lector_speciality}`, {
        responseType: "blob",
      })
      .then(function (response) {
        console.log(response);
        fileDownload(response.data, grant.lector_speciality);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const format = (inputDate) => {
    let date, month, year;

    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date.toString().padStart(2, "0");

    month = month.toString().padStart(2, "0");

    return `${date}.${month}.${year}`;
  };

  if (loading)
    return (
      <div className="create-page" style={{ minHeight: 250, marginTop: 30 }}>
        <div
          className="data-show"
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <h5 style={{ fontWeight: 700 }}>ДАННЫЕ ПРОЕКТА</h5>
        </div>
        <div
          style={{ marginTop: "5%" }}
          className="spinner-border text-success"
          role="status"
        ></div>
      </div>
    );

  return (
    <div className="create-page" style={{ minHeight: 250, marginTop: 30 }}>
      <div
        className="data-show"
        style={{ display: "flex", flexDirection: "column", width: "100%" }}
      >
        <h5 style={{ fontWeight: 700 }}>ДАННЫЕ ПРОЕКТА</h5>
        <h6 style={{ marginTop: 20, fontWeight: 700 }}>Название проекта</h6>
        <p>{grant.project_name}</p>

        <h6 style={{ marginTop: 20, fontWeight: 700 }}>Дата реализации</h6>
        <p>{format(new Date(grant.project_date))}</p>

        <h6 style={{ marginTop: 20, fontWeight: 700 }}>Место проведения</h6>
        <p>{grant.project_location}</p>

        <h6 style={{ marginTop: 20, fontWeight: 700 }}>Описание проекта</h6>
        <Button className="file-input" onClick={downloadProjectHandler}>
          Скачать файл
        </Button>

        {grant.sum === 0 && (
          <h5 style={{ marginTop: 30, fontWeight: 700 }}>ДАННЫЕ ЛЕКТОРА</h5>
        )}
        {grant.sum === 1 && (
          <h5 style={{ marginTop: 30, fontWeight: 700 }}></h5>
        )}
        <h6 style={{ marginTop: 20, fontWeight: 700 }}>ИНН</h6>
        <p>{grant.lector_id}</p>

        {grant.sum === 0 && (
          <div>
            <h6 style={{ marginTop: 20, fontWeight: 700 }}>СНИЛС</h6>
            <p>{grant.lector_snils}</p>
          </div>
        )}

        {grant.sum === 0 && grant.user_name && (
          <div>
            <h6 style={{ marginTop: 20, fontWeight: 700 }}>
              Фамилия Имя Отчество
            </h6>
            <p>{grant.user_name}</p>
          </div>
        )}
        {grant.sum === 0 && grant.user_birthdate && (
          <div>
            <h6 style={{ marginTop: 20, fontWeight: 700 }}>Дата рождения</h6>
            <p>{grant.user_birthdate}</p>
          </div>
        )}
        {grant.sum === 0 && (
          <div>
            <h6 style={{ marginTop: 20, fontWeight: 700 }}>
              Паспортные данные
            </h6>
            <p>
              {grant.lector_pasport_serial} {grant.lector_pasport_number}
            </p>
          </div>
        )}

        <h6 style={{ marginTop: 20, fontWeight: 700 }}>
          Адрес электронной почты
        </h6>
        <p>{grant.lector_email}</p>

        <h6 style={{ marginTop: 20, fontWeight: 700 }}>Телефон</h6>
        <p>{grant.lector_phone_number}</p>

        {grant.sum == 0 && (
          <div>
            <h6 style={{ marginTop: 20, fontWeight: 700 }}>Специальность</h6>
            <Button className="file-input" onClick={downloadDiplomaHandler}>
              Скачать файл
            </Button>
          </div>
        )}

        <h5 style={{ marginTop: 30, fontWeight: 700 }}>БАНКОВСКИЕ РЕКВИЗИТЫ</h5>
        <h6 style={{ marginTop: 20, fontWeight: 700 }}>Номер счета</h6>
        <p>{grant.contract_bill_number}</p>

        <h6 style={{ marginTop: 20, fontWeight: 700 }}>БИК</h6>
        <p>{grant.contract_bank_bik}</p>
      </div>
    </div>
  );
};
