import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const Grants = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const history = useNavigate();
  const [grants, setGrants] = useState([]);
  const [user, setUser] = useState({});
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    axios
      .get(`/api/auth/get_user/${userData.data.id}`, {
        headers: { ["Content-Type"]: "application/json" },
      })
      .then(function (response) {
        console.log(response);
        setUser(response.data.data);
        getUserGrants();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getUserGrants = () => {
    axios
      .get(`/api/grant/get_user_grants/${userData.data.id}`, {
        headers: { ["Content-Type"]: "application/json" },
      })
      .then(function (response) {
        console.log(response);
        setGrants(response.data.reverse());
        setLoading2(!loading2);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const format = (inputDate) => {
    let date, month, year;

    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date.toString().padStart(2, "0");

    month = month.toString().padStart(2, "0");

    return `${date}.${month}.${year}`;
  };

  if (loading2)
    return (
      <div
        style={{ marginTop: "20%" }}
        className="spinner-border text-success"
        role="status"
      ></div>
    );

  return (
    <div className="grants">
      <h2>
        {user.first_name} {user.last_name}
      </h2>
      <Button
        style={{ marginTop: 24 }}
        className="button-big"
        onClick={() => {
          history("/create_grant");
        }}
      >
        Запросить новый грант
      </Button>
      <Table className="table-own" bordered hover>
        <thead>
          <tr
            style={{ background: "#00984D", color: "white", cursor: "pointer" }}
          >
            <th colSpan="4">
              <span className="table-header" style={{ float: "left" }}>
                Посмотреть список запросов
              </span>
              <span className="table-header" style={{ float: "right" }}>
                {grants.length}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {grants.length > 0 &&
            grants.map((grant) => {
              return (
                <tr key={grant.id}>
                  <td className="table-td">{grant.project_name}</td>
                  <td style={{ width: "25%" }} className="table-td">
                    {format(new Date(grant.project_date))}
                  </td>
                  <td className="table-td">{grant.contest_comission_answer}</td>
                  <td className="table-td-active">
                    <Button
                      className="button"
                      onClick={() => {
                        history(`/user/${grant.id}/project`);
                      }}
                    >
                      Подробнее
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};
