import React, { useState } from "react";
import { Controls, GrantsList } from "./blocks";
import { FILTER_MODS } from "./config";

import "./styles.scss";

const ExpertCabinet = () => {
  const [filter, setFilter] = useState(FILTER_MODS.all);

  const onFilterChange = (propMode) => setFilter(propMode);

  return (
    <div className="expert-cabinet">
      <Controls onFilterChange={onFilterChange} filterMode={filter} />
      <GrantsList filterMode={filter} />
    </div>
  );
};

export default ExpertCabinet;
