import { Button } from "react-bootstrap";
import "./styles.scss";
import { formatDate } from "utils";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";

const TableRow = ({ grant }) => {
  const navigate = useNavigate();

  const { id, grant_id, grant_name, grant_date, answers } = grant;

  const onNavigate = useCallback(
    () => navigate(`/expert/${id}/grant`),
    [id, navigate]
  );

  const grantStatusMemo = useMemo(() => {
    return answers ? "С ответом" : "Без ответа";
  }, [answers]);

  return (
    <tr className="table-row">
      <td className="table-row__cell">{grant_id}</td>
      <td colSpan={2} className="table-row__cell">
        {grant_name}
      </td>
      <td className="table-row__cell table-row__cell_short">
        {formatDate(new Date(grant_date))}
      </td>
      <td className="table-row__cell">{grantStatusMemo}</td>
      <td className="table-row__cell table-row__cell_flex-end">
        <Button className="button" onClick={onNavigate}>
          Подробнее
        </Button>
      </td>
    </tr>
  );
};

export default TableRow;
