const TableHead = ({ grantsLength }) => {
  return (
    <thead className="table-head">
      <tr className="table-head__row">
        <th className="table-head__cell" colSpan="5">
          <span className="table-head__span table-head__span_left">
            Список запросов
          </span>
        </th>
        <th>
          <span
            className="table-head__span table-head__span_right"
            style={{ float: "right" }}
          >
            {grantsLength}
          </span>
        </th>
      </tr>
    </thead>
  );
};

export default TableHead;
