const ProjectField = ({ title, description }) => {
  return (
    <>
      <h6 className="expert-project__title">{title}</h6>
      <p className="expert-project__text-regular">{description}</p>
    </>
  );
};

export default ProjectField;
