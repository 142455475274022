import { Button, Card } from "react-bootstrap";
import { formatDate } from "utils";
import "./styles.scss";
import { ProjectField } from "./components";

import React, { useCallback } from "react";
import fileDownload from "js-file-download";

import { getProjectDownload, getDiplomaDownload } from "pages/Expert/api";

const ProjectInfo = ({ grant }) => {
  const { project_name, project_date, project_location, sum } = grant;

  const isPrivateProject = sum === 0;

  const onDownloadHandler = useCallback((promise, downloadOption) => {
    promise
      .then((response) => {
        console.log(response);

        fileDownload(response.data, downloadOption);
      })
      .catch((error) => console.log(error));
  }, []);

  const downloadProjectHandler = () => {
    onDownloadHandler(
      getProjectDownload(grant.project_description),
      grant.project_description
    );
  };

  const downloadDiplomaHandler = () => {
    onDownloadHandler(
      getDiplomaDownload(grant.lector_speciality),
      grant.lector_speciality
    );
  };

  return (
    <Card className="expert-project">
      <div className="expert-project__container">
        <h5 className="expert-project__title">ДАННЫЕ ПРОЕКТА</h5>

        <ProjectField title="Название проекта" description={project_name} />
        <ProjectField
          title="Дата реализации"
          description={formatDate(new Date(project_date))}
        />
        <ProjectField title="Место реализации" description={project_location} />

        <h6 className="expert-project__title">Описание проекта</h6>
        <Button className="file-input" onClick={downloadProjectHandler}>
          Скачать файл
        </Button>

        {isPrivateProject && (
          <h5 style={{ marginTop: 30, fontWeight: 700 }}>ДАННЫЕ ЛЕКТОРА</h5>
        )}

        <ProjectField title="ИНН" description={grant.lector_id} />

        {isPrivateProject && (
          <>
            <div>
              <ProjectField title="СНИЛС" description={grant.lector_snils} />
            </div>
            <div>
              <ProjectField
                title="Фамилия Имя Отчество"
                description={grant.user_name}
              />
            </div>
            <div>
              <ProjectField
                title="Дата рождения"
                description={grant.user_birthdate}
              />
            </div>
            <div>
              <ProjectField
                title="Паспортные данные"
                description={`${grant.lector_pasport_serial} ${grant.lector_pasport_number}`}
              />
            </div>
          </>
        )}

        <ProjectField
          title="Адрес электронной почты"
          description={grant.lector_email}
        />
        <ProjectField title="Телефон" description={grant.lector_phone_number} />

        {isPrivateProject && (
          <div>
            <h6 className="expert-project__title">Специальность</h6>
            <Button className="file-input" onClick={downloadDiplomaHandler}>
              Скачать файл
            </Button>
          </div>
        )}

        <h5 className="expert-project__title" style={{ marginTop: 30 }}>
          БАНКОВСКИЕ РЕКВИЗИТЫ
        </h5>

        <ProjectField
          title="Номер счета"
          description={grant.contract_bill_number}
        />
        <ProjectField title="БИК" description={grant.contract_bank_bik} />
      </div>
    </Card>
  );
};

export default ProjectInfo;
