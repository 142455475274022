import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router";
import fileDownload from "js-file-download";

const AdminExpertsOld = ({ getInitialData, project }) => {
  const params = useParams();

  const [file, setFile] = useState({
    expert_answer_1_file: null,
    expert_answer_2_file: null,
    expert_answer_3_file: null,
  });

  const [isLoadingFile, setLoadingFile] = useState({
    expert_answer_1_file: false,
    expert_answer_2_file: false,
    expert_answer_3_file: false,
  });

  const fileHandler = (event) => {
    setFile({ ...file, [event.target.name]: event.target.files[0] });
  };

  const uploadExpert1Handler = async () => {
    console.log("выполняется");
    const formData = new FormData();

    formData.append(
      "MyFile",
      file.expert_answer_1_file,
      file.expert_answer_1_file.name
    );

    setLoadingFile({ ...isLoadingFile, expert_answer_1_file: true });

    await axios
      .post("/api/file/upload", formData, {
        headers: { "Content-Type": "application/json" },
      })
      .then(async function (response) {
        await axios
          .post(
            `/api/admin/grant_update_expert_file_1/${params.id}`,
            { expert_answer_1_file: response.data.filename },
            { headers: { "Content-Type": "application/json" } }
          )
          .then(function (response) {
            console.log(response);
            console.log("выполняется");
            getInitialData();

            setLoadingFile({ ...isLoadingFile, expert_answer_1_file: false });
          })
          .catch(function (error) {
            console.log(error);

            setLoadingFile({ ...isLoadingFile, expert_answer_1_file: false });
          });
      })
      .catch(function (error) {
        console.log(error);

        setLoadingFile({ ...isLoadingFile, expert_answer_1_file: false });
      });
  };

  const uploadExpert2Handler = async () => {
    const formData = new FormData();

    formData.append(
      "MyFile",
      file.expert_answer_2_file,
      file.expert_answer_2_file.name
    );

    setLoadingFile({ ...isLoadingFile, expert_answer_2_file: true });

    await axios
      .post("/api/file/upload", formData, {
        headers: { "Content-Type": "application/json" },
      })
      .then(async function (response) {
        await axios
          .post(
            `/api/admin/grant_update_expert_file_2/${params.id}`,
            { expert_answer_2_file: response.data.filename },
            { headers: { "Content-Type": "application/json" } }
          )
          .then(function (response) {
            console.log(response);
            getInitialData();

            setLoadingFile({ ...isLoadingFile, expert_answer_2_file: false });
          })
          .catch(function (error) {
            console.log(error);
            setLoadingFile({ ...isLoadingFile, expert_answer_2_file: false });
          });
      })
      .catch(function (error) {
        console.log(error);

        setLoadingFile({ ...isLoadingFile, expert_answer_2_file: false });
      });
  };

  const uploadExpert3Handler = async () => {
    const formData = new FormData();
    formData.append(
      "MyFile",
      file.expert_answer_3_file,
      file.expert_answer_3_file.name
    );

    setLoadingFile({ ...isLoadingFile, expert_answer_3_file: true });

    await axios
      .post("/api/file/upload", formData, {
        headers: { "Content-Type": "application/json" },
      })
      .then(async function (response) {
        await axios
          .post(
            `/api/admin/grant_update_expert_file_3/${params.id}`,
            { expert_answer_3_file: response.data.filename },
            { headers: { "Content-Type": "application/json" } }
          )
          .then(function (response) {
            console.log(response);
            getInitialData();

            setLoadingFile({ ...isLoadingFile, expert_answer_3_file: false });
          })
          .catch(function (error) {
            console.log(error);

            setLoadingFile({ ...isLoadingFile, expert_answer_3_file: false });
          });
      })
      .catch(function (error) {
        console.log(error);

        setLoadingFile({ ...isLoadingFile, expert_answer_3_file: false });
      });
  };

  useEffect(() => {
    if (file.expert_answer_1_file !== null) {
      uploadExpert1Handler();

      setFile({ ...file, expert_answer_1_file: null });
    }
    if (file.expert_answer_2_file !== null) {
      uploadExpert2Handler();
      setFile({ ...file, expert_answer_2_file: null });
    }
    if (file.expert_answer_3_file !== null) {
      uploadExpert3Handler();

      setFile({ ...file, expert_answer_3_file: null });
    }
  });

  const downloadHandler = (file) => {
    axios
      .get(`/api/file/download/${file}`, { responseType: "blob" })
      .then(function (response) {
        console.log(response);
        fileDownload(response.data, file);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {project.expert_answer_1_file !== "" ? (
        <>
          <h6 style={{ marginTop: 30, fontWeight: 700 }}>
            Одобрено экспертами №1
          </h6>
          <button
            style={{ marginTop: 29 }}
            className="file-input"
            name="experts_approved_1"
            onClick={() => {
              downloadHandler(project.expert_answer_1_file);
            }}
          >
            Скачать файл
          </button>
        </>
      ) : (
        <>
          <h6 style={{ marginTop: 30, fontWeight: 700 }}>
            Одобрено экспертами №1
          </h6>
          <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
            <label
              className="file-input"
              name="experts_approved_1"
              htmlFor="experts_approved_1"
            >
              <input
                disabled={project.expert_answer !== "Принято"}
                id="experts_approved_1"
                name="expert_answer_1_file"
                onChange={(event) => {
                  setFile({
                    ...file,
                    expert_answer_1_file: event.target.files[0],
                  });
                }}
                type="file"
                style={{ display: "none" }}
              />
              Прикрепить файл
            </label>
            {isLoadingFile.expert_answer_1_file && (
              <Spinner
                animation="border"
                size="sm"
                color="green"
                role="status"
              />
            )}
          </div>
        </>
      )}

      {project.expert_answer_2_file !== "" ? (
        <>
          <h6 style={{ marginTop: 30, fontWeight: 700 }}>
            Одобрено экспертами №2
          </h6>
          <button
            style={{ marginTop: 29 }}
            className="file-input"
            name="experts_approved_1"
            onClick={() => {
              downloadHandler(project.expert_answer_2_file);
            }}
          >
            Скачать файл
          </button>
        </>
      ) : (
        <>
          <h6 style={{ marginTop: 30, fontWeight: 700 }}>
            Одобрено экспертами №2
          </h6>
          <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
            <label
              className="file-input"
              name="experts_approved_1"
              htmlFor="experts_approved_2"
            >
              <input
                disabled={project.expert_answer !== "Принято"}
                id="experts_approved_2"
                type="file"
                name="expert_answer_2_file"
                onChange={(event) => {
                  setFile({
                    ...file,
                    expert_answer_2_file: event.target.files[0],
                  });
                }}
                style={{ display: "none" }}
              />
              Прикрепить файл
            </label>
            {isLoadingFile.expert_answer_2_file && (
              <Spinner
                animation="border"
                size="sm"
                color="green"
                role="status"
              />
            )}
          </div>
        </>
      )}

      {project.expert_answer_3_file !== "" ? (
        <>
          <h6 style={{ marginTop: 30, fontWeight: 700 }}>
            Одобрено экспертами №3
          </h6>
          <button
            style={{ marginTop: 29 }}
            className="file-input"
            name="experts_approved_1"
            onClick={() => {
              downloadHandler(project.expert_answer_3_file);
            }}
          >
            Скачать файл
          </button>
        </>
      ) : (
        <>
          <h6 style={{ marginTop: 30, fontWeight: 700 }}>
            Одобрено экспертами №3
          </h6>
          <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
            <label
              className="file-input"
              name="experts_approved_1"
              htmlFor="experts_approved_3"
            >
              <input
                disabled={project.expert_answer !== "Принято"}
                id="experts_approved_3"
                type="file"
                name="expert_answer_3_file"
                onChange={(event) => {
                  setFile({
                    ...file,
                    expert_answer_3_file: event.target.files[0],
                  });
                }}
                style={{ display: "none" }}
              />
              Прикрепить файл
            </label>
            {isLoadingFile.expert_answer_3_file && (
              <Spinner
                animation="border"
                size="sm"
                color="green"
                role="status"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AdminExpertsOld;
