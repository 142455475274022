import { getExpert } from "pages/admin/api";
import { useEffect, useMemo, useState } from "react";

const StatsExpertAnswer = ({ record, type }) => {
  const [expert, setExpert] = useState({});

  const onValueMemo = useMemo(() => {
    switch (type) {
      case "goals":
        return record?.answers?.goals;

      case "recommendation":
        return record?.answers?.recommendation;

      default:
        return "";
    }
  }, [type, record]);

  useEffect(() => {
    getExpert(record.user_id)
      .then(({ data }) => {
        setExpert(data.expert);
      })
      .catch((error) => console.log(error));
  }, [record]);

  return (
    <div className="admin-answers__item">
      <span className="admin-answers__item-title">
        {expert?.first_name} {expert?.last_name}:
      </span>
      {onValueMemo ? (
        <span className="admin-answers__item-description">{onValueMemo}</span>
      ) : (
        <span className="admin-answers__item-description admin-answers__item-description_dim">
          Нет ответа
        </span>
      )}
    </div>
  );
};

export default StatsExpertAnswer;
