import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import {
  Button,
  Form,
  FormControl,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import back from "../images/utils/back.svg";
import { useNavigate } from "react-router-dom";
import ok from "../images/utils/correct.png";
import axios from "axios";
import fileDownload from "js-file-download";

export const CreateNewGrant = () => {
  const params = useParams();
  const history = useNavigate();
  const width = window.innerWidth;

  const userData = JSON.parse(localStorage.getItem("userData"));

  const [permission1, setPermission1] = useState(false);
  const [permission2, setPermission2] = useState(false);

  const [isLoadingDiploma, setLoadingDiploma] = useState(false);
  const [isLoadingProject, setLoadingProject] = useState(false);

  const [uploadErrors, setUploadErrors] = useState({
    diploma: null,
    project: null,
  });

  const [error, setError] = useState("");
  const [file, setFile] = useState({
    project: null,
    diploma: null,
  });

  const isIndividualUser = params.sum == 0;

  const [data, setData] = useState({
    user_id: userData.data.id,
    user_name: "",
    user_birthdate: "",
    sum: params.sum,
    project_description: "",
    lector_speciality: "",
    project_name: "",
    project_date: "",
    project_location: "",
    lector_id: userData.data.inn || "",
    lector_snils: userData.data.snils || "",
    lector_pasport_serial: userData.data.passport_serial || "",
    lector_pasport_number: userData.data.passport_number || "",
    lector_email: userData.data.user_email || "",
    lector_phone_number: userData.data.phone_number || "",
    contract_bill_number: "",
    contract_bank_bik: "",
  });

  const changeHandler = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    setError("");
  };

  const fileHandler = (event) => {
    if (event.target.files[0]) {
      if (event.target.name === "project") {
        setData({ ...data, project_description: "" });
      }

      if (event.target.name === "diploma") {
        setData({ ...data, lector_speciality: "" });
      }

      setFile({ ...file, [event.target.name]: event.target.files[0] });
    }
  };

  const uploadDiplomaHandler = async () => {
    const formData = new FormData();

    setLoadingDiploma(true);
    setUploadErrors({ ...uploadErrors, diploma: null });

    formData.append("MyFile", file.diploma, file.diploma.name);

    await axios
      .post("/api/file/upload", formData, {
        headers: { ["Content-Type"]: "application/json" },
      })
      .then(function (response) {
        setData({ ...data, lector_speciality: response.data.filename });

        setLoadingDiploma(false);
      })
      .catch(function ({ response }) {
        setUploadErrors({ ...uploadErrors, diploma: response.data.error });

        setLoadingDiploma(false);
      });
  };

  const uploadProjectHandler = async () => {
    const formData = new FormData();

    formData.append("MyFile", file.project, file.project.name);

    setLoadingProject(true);
    setUploadErrors({ ...uploadErrors, project: null });

    await axios
      .post("/api/file/upload", formData, {
        headers: { ["Content-Type"]: "application/json" },
      })
      .then(function (response) {
        setLoadingProject(false);

        setData({ ...data, project_description: response.data.filename });
      })
      .catch(function ({ response }) {
        setLoadingProject(false);

        setUploadErrors({ ...uploadErrors, project: response.data.error });
      });
  };

  const confirmHandler = async () => {
    console.log(data);
    await axios
      .post(
        "/api/grant/create_grant",
        { ...data },
        { headers: { ["Content-Type"]: "application/json" } }
      )
      .then(function (response) {
        console.log(response);
        history("/");
        window.location.reload();
      })
      .catch(function (error) {
        setError(error.response.data);
        window.scrollTo(0, 0);
      });
  };

  const onlyNumbers = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setData({ ...data, [event.target.name]: event.target.value });
      setError("");
    }
  };

  const snils = (event) => {
    const re = /^[0123456789 _\-]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setData({ ...data, [event.target.name]: event.target.value });
      setError("");
    }
  };

  const phone = (event) => {
    const re = /^[0-9]*$/;
    if (event.target.value !== "7" && event.target.value.length === 1) {
      event.target.value = "7" + event.target.value;
    }
    if (event.target.value === "" || re.test(event.target.value)) {
      setData({ ...data, [event.target.name]: event.target.value });
    }
  };

  const downloadDoc1 = () => {
    axios
      .get(`/api/auth/get_position`, { responseType: "blob" })
      .then(function (response) {
        fileDownload(response.data, "position.pdf");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const downloadDoc2 = () => {
    axios
      .get(`/api/auth/get_program`, { responseType: "blob" })
      .then(function (response) {
        fileDownload(response.data, "program.pdf");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const renderError = useCallback((value) => {
    return <span style={{ marginLeft: 15, color: "red" }}>{value}</span>;
  }, []);

  const renderProjectUploadStatus = useMemo(() => {
    if (isLoadingProject) {
      return (
        <Spinner
          style={{ marginLeft: 15 }}
          animation="border"
          size="sm"
          color="green"
          role="status"
        />
      );
    }

    if (!isLoadingProject && data.project_description !== "") {
      return (
        <span>
          {" "}
          <img style={{ width: 25, height: 25 }} alt="" src={ok} />
        </span>
      );
    }

    if (uploadErrors.project) {
      return renderError(uploadErrors.project);
    }
  }, [
    renderError,
    isLoadingProject,
    uploadErrors.project,
    data.project_description,
  ]);

  const renderProjectDiplomaStatus = useMemo(() => {
    if (isLoadingDiploma) {
      return (
        <Spinner
          style={{ marginLeft: 15 }}
          animation="border"
          size="sm"
          color="green"
          role="status"
        />
      );
    }

    if (isLoadingDiploma === false && data.lector_speciality !== "") {
      return (
        <span>
          {" "}
          <img style={{ width: 25, height: 25 }} alt="" src={ok} />
        </span>
      );
    }

    if (uploadErrors.diploma) {
      return renderError(uploadErrors.diploma);
    }
  }, [
    renderError,
    isLoadingDiploma,
    uploadErrors.diploma,
    data.lector_speciality,
  ]);

  useEffect(() => {
    if (file.diploma !== null) {
      uploadDiplomaHandler();
    }
  }, [file.diploma]);

  useEffect(() => {
    if (file.project !== null) {
      uploadProjectHandler();
    }
  }, [file.project]);

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  return (
    <>
      <Button
        className="back-button"
        onClick={() => {
          history("/create_grant");
        }}
      >
        <img src={back} alt="" /> Вернуться назад
      </Button>
      {width > 700 && (
        <>
          <div style={{ display: "flex", marginTop: 95 }}>
            <div style={{ marginTop: -30 }}>
              <div className="side-nav">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label className="item-active">
                    <input
                      id="indicator-1"
                      name="page"
                      value="1"
                      type="radio"
                      defaultChecked={true}
                    />
                    Проекты
                  </label>
                  <label className="item-nonactive">
                    <input
                      disabled={true}
                      id="indicator-2"
                      name="page"
                      value="2"
                      type="radio"
                    />
                    Экспертный совет
                  </label>
                  <label className="item-nonactive">
                    <input
                      disabled={true}
                      id="indicator-3"
                      name="page"
                      value="3"
                      type="radio"
                    />
                    Конкурсная комиссия
                  </label>
                  <label
                    className="item-nonactive"
                    style={{ marginBottom: 12 }}
                  >
                    <input
                      disabled={true}
                      id="indicator-4"
                      name="page"
                      value="4"
                      type="radio"
                    />
                    Договор
                  </label>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="create-page">
        <div>
          <h5 className="data-set-name">ДАННЫЕ ПРОЕКТА</h5>
          <p
            className="input-label"
            style={{ color: "red", margin: "15px 0 0 15px", height: 40 }}
          >
            {error}
          </p>
          <Form.Label
            className="input-label"
            style={{ marginTop: 5 }}
            htmlFor="basic-url"
          >
            Название проекта
          </Form.Label>
          <InputGroup className="input-modal">
            <FormControl
              className="input-modal"
              required
              type="text"
              value={data.project_name}
              name="project_name"
              onChange={changeHandler}
              style={{ borderRadius: 20 }}
              id="basic-url"
              aria-describedby="basic-addon3"
            />
          </InputGroup>
          <Form.Label
            className="input-label"
            style={{ marginTop: 20 }}
            htmlFor="basic-url"
          >
            Дата реализации
          </Form.Label>
          <InputGroup className="input-modal">
            <FormControl
              className="input-modal"
              required
              type="date"
              value={data.project_date}
              name="project_date"
              onChange={changeHandler}
              style={{ borderRadius: 20, padding: "4px 6px 6px 10px" }}
              id="basic-url"
              aria-describedby="basic-addon3"
            />
          </InputGroup>

          <Form.Label
            className="input-label"
            style={{ marginTop: 20 }}
            htmlFor="basic-url"
          >
            Место проведения
          </Form.Label>
          <InputGroup className="input-modal">
            <FormControl
              className="input-modal"
              placeholder="место проведения научного мероприятия"
              required
              type="text"
              value={data.project_location}
              name="project_location"
              onChange={changeHandler}
              style={{ borderRadius: 20 }}
              id="basic-url"
              aria-describedby="basic-addon3"
            />
          </InputGroup>

          <Form.Label
            className="input-label"
            style={{ marginTop: 20 }}
            htmlFor="basic-url"
          >
            Описание проекта
          </Form.Label>
          <input
            required
            name="project"
            onChange={fileHandler}
            id="project"
            disabled={isLoadingProject}
            type="file"
            style={{ opacity: 0, height: 0 }}
          />
          <label className="file-input" name="project-label" htmlFor="project">
            Прикрепить файл
          </label>
          {renderProjectUploadStatus}

          {isIndividualUser && (
            <h5 className="data-set-name" style={{ marginTop: 30 }}>
              ДАННЫЕ ЛЕКТОРА
            </h5>
          )}
          {!isIndividualUser && (
            <h5 className="data-set-name" style={{ marginTop: 30 }}></h5>
          )}
          <Form.Label
            className="input-label"
            style={{ marginTop: 20 }}
            htmlFor="basic-url"
          >
            ИНН
          </Form.Label>
          <InputGroup className="input-modal">
            <FormControl
              className="input-modal"
              placeholder="XXXXXXXXXXXX"
              maxLength="12"
              required
              type="text"
              pattern="[0-9]*"
              value={data.lector_id}
              name="lector_id"
              onChange={onlyNumbers}
              style={{ borderRadius: 20 }}
              id="basic-url"
              aria-describedby="basic-addon3"
            />
          </InputGroup>

          {isIndividualUser && (
            <div>
              <Form.Label
                className="input-label"
                style={{ marginTop: 20 }}
                htmlFor="basic-url"
              >
                СНИЛС
              </Form.Label>
              <InputGroup className="input-modal">
                <FormControl
                  className="input-modal"
                  required
                  type="text"
                  maxLength="14"
                  value={data.lector_snils}
                  placeholder="ХХХ-ХХХ-ХХХ YY"
                  name="lector_snils"
                  onChange={snils}
                  style={{ borderRadius: 20 }}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                />
              </InputGroup>

              <Form.Label
                className="input-label"
                style={{ marginTop: 20 }}
                htmlFor="basic-url"
              >
                Фамилия Имя Отчество
              </Form.Label>
              <InputGroup className="input-modal">
                <FormControl
                  className="input-modal"
                  required
                  type="text"
                  placeholder="ФИО специалиста"
                  value={data.user_name}
                  name="user_name"
                  onChange={changeHandler}
                  style={{ borderRadius: 20 }}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                />
              </InputGroup>
              <Form.Label
                className="input-label"
                style={{ marginTop: 20 }}
                htmlFor="basic-url"
              >
                Дата рождения
              </Form.Label>
              <InputGroup className="input-modal">
                <InputMask
                  className="input-modal form-control"
                  mask="99.99.9999"
                  maskPlaceholder="дд.мм.гггг"
                  required
                  spellcheck="false"
                  style={{ borderRadius: 20 }}
                  placeholder="Дата рождения"
                  value={data.user_birthdate}
                  name="user_birthdate"
                  onChange={changeHandler}
                />
              </InputGroup>
              <Form.Label
                className="input-label"
                style={{ marginTop: 20 }}
                htmlFor="basic-url"
              >
                Паспортные данные
              </Form.Label>
              <div style={{ display: "flex" }}>
                <InputGroup
                  style={{ width: "30%", marginRight: 10 }}
                  className="input-modal"
                >
                  <FormControl
                    className="input-modal"
                    placeholder="Серия"
                    maxLength="4"
                    required
                    type="text"
                    value={data.lector_pasport_serial}
                    name="lector_pasport_serial"
                    onChange={onlyNumbers}
                    style={{ borderRadius: 20 }}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                  />
                </InputGroup>
                <InputGroup style={{ width: "70%" }} className="input-modal">
                  <FormControl
                    className="input-modal"
                    required
                    placeholder="Номер"
                    maxLength="6"
                    type="text"
                    value={data.lector_pasport_number}
                    name="lector_pasport_number"
                    onChange={onlyNumbers}
                    style={{ borderRadius: 20 }}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                  />
                </InputGroup>
              </div>
            </div>
          )}

          <Form.Label
            className="input-label"
            style={{ marginTop: 20 }}
            htmlFor="basic-url"
          >
            Адрес электронной почты
          </Form.Label>
          <InputGroup className="input-modal">
            <FormControl
              className="input-modal"
              placeholder="example@example.com"
              required
              type="text"
              value={data.lector_email}
              name="lector_email"
              onChange={changeHandler}
              style={{ borderRadius: 20 }}
              id="basic-url"
              aria-describedby="basic-addon3"
            />
          </InputGroup>

          <Form.Label
            className="input-label"
            style={{ marginTop: 20 }}
            htmlFor="basic-url"
          >
            Телефон
          </Form.Label>
          <InputGroup className="input-modal">
            <FormControl
              className="input-modal"
              required
              type="text"
              maxLength="11"
              value={data.lector_phone_number}
              name="lector_phone_number"
              onChange={phone}
              style={{ borderRadius: 20 }}
              id="basic-url"
              aria-describedby="basic-addon3"
            />
          </InputGroup>

          {isIndividualUser && (
            <div>
              <Form.Label
                className="input-label"
                style={{ marginTop: 20 }}
                htmlFor="basic-url"
              >
                Специальность
              </Form.Label>
              <input
                required
                name="diploma"
                onChange={fileHandler}
                id="diploma"
                type="file"
                disabled={isLoadingDiploma}
                style={{ opacity: 0 }}
              />
              <label
                style={{ width: 250 }}
                className="file-input"
                name="diploma-label"
                htmlFor="diploma"
              >
                Прикрепить диплом
              </label>
              {renderProjectDiplomaStatus}
            </div>
          )}
          <h5
            className="data-set-name"
            style={{ marginTop: 30, fontWeight: 700 }}
          >
            БАНКОВСКИЕ РЕКВИЗИТЫ
          </h5>
          <Form.Label
            className="input-label"
            style={{ marginTop: 20 }}
            htmlFor="basic-url"
          >
            Расчетный счет
          </Form.Label>
          <InputGroup className="input-modal">
            <FormControl
              className="input-modal"
              required
              type="text"
              value={data.contract_bill_number}
              placeholder="XXXXXXXXXXXXXXXXXXXX"
              maxLength="20"
              name="contract_bill_number"
              onChange={onlyNumbers}
              style={{ borderRadius: 20 }}
              id="basic-url"
              aria-describedby="basic-addon3"
            />
          </InputGroup>
          <Form.Label
            className="input-label"
            style={{ marginTop: 20 }}
            htmlFor="basic-url"
          >
            БИК
          </Form.Label>
          <InputGroup className="input-modal">
            <FormControl
              className="input-modal"
              required
              type="text"
              placeholder="XXXXXXXXX"
              maxLength="9"
              value={data.contract_bank_bik}
              name="contract_bank_bik"
              onChange={onlyNumbers}
              style={{ borderRadius: 20 }}
              id="basic-url"
              aria-describedby="basic-addon3"
            />
          </InputGroup>

          <InputGroup
            style={{
              marginTop: 30,
              marginLeft: 15,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Form.Check
              style={{ marginRight: 5 }}
              onChange={() => {
                setPermission1(!permission1);
              }}
              type={"checkbox"}
              id="checkbox"
            />
            <span className="modal-span">
              Ознакомлен с{" "}
              <span className="download-span" onClick={downloadDoc1}>
                Положением
              </span>{" "}
              о грантах
            </span>
          </InputGroup>

          <InputGroup
            style={{
              marginTop: 10,
              marginLeft: 15,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Form.Check
              style={{ marginRight: 5 }}
              onChange={() => {
                setPermission2(!permission2);
              }}
              type={"checkbox"}
              id="checkbox"
            />
            <span className="modal-span">
              Ознакомлен с{" "}
              <span className="download-span" onClick={downloadDoc2}>
                Поощрительной программой
              </span>
            </span>
          </InputGroup>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              disabled={!permission2 || !permission1}
              onClick={confirmHandler}
              style={{ marginTop: 30 }}
              className="button"
            >
              Сохранить
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
