import StatsExpertAnswer from "./StatsExpertAnswer";

const AdminExpertsAnswers = ({ records }) => {
  return (
    <div className="admin-answers">
      <span className="admin-answers__title">Ответы</span>

      <div className="admin-answers__container">
        <span className="admin-answers__question">
          Проект соответствуют целям и задачам Поощрительной программы. Участник
          проекта отвечает требованиям, предусмотренным пунктом 1.3.Положения о
          пожертвованиях(грантах) Поощрительной программы
        </span>
        <div className="admin-answers__content">
          {records.map((record) => {
            return <StatsExpertAnswer record={record} type="goals" />;
          })}
        </div>
      </div>
      <div className="admin-answers__container">
        <span className="admin-answers__question">
          Рекомендация о целесообразности поддержки проекта
        </span>
        <div className="admin-answers__content">
          {records.map((record) => {
            return <StatsExpertAnswer record={record} type="recommendation" />;
          })}
        </div>
      </div>
    </div>
  );
};

export default AdminExpertsAnswers;
