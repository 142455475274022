import AdminRoutes from './partials/adminRoutes.jsx'
import CommonRoutes from './partials/commonRoutes.jsx'
import ExpertRoutes from './partials/expertRoutes.jsx'
import UserRoutes from './partials/userRoutes.jsx'



export const useRoutes = ({ isAuthenticated, isAdmin, isExpert }) => {
    if (isAuthenticated) {
        if (isAdmin) {
            return <AdminRoutes />
        }

        if (isExpert) {
            return <ExpertRoutes />
        }

        return <UserRoutes />
    }

    return <CommonRoutes />
}
