import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";

import "./styles.scss";

export const AllGrants = () => {
  const [grants, setGrants] = useState([]);

  const getUserGrants = () => {
    axios
      .get(`/api/grant/get_user_grants`, {
        headers: { "Content-Type": "application/json" },
      })
      .then(function (response) {
        setGrants(response.data.reverse());
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const format = (inputDate) => {
    let date, month, year;

    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date.toString().padStart(2, "0");

    month = month.toString().padStart(2, "0");

    return `${date}.${month}.${year}`;
  };

  const history = useNavigate();

  const onDeleteGrant = useCallback((id) => {
    axios
      .post(`/api/admin/delete_grant/${id}`, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => setGrants(response.data.grants))
      .catch((error) => console.log(error));
  }, []);

  const renderGrants = useMemo(() => {
    return grants.map((grant) => {
      return (
        <tr key={grant.id}>
          <td>{grant.project_name}</td>
          <td style={{ textAlign: "center", width: 150 }}>
            {format(new Date(grant.project_date))}
          </td>
          <td style={{ textAlign: "center", width: 200 }}>
            {grant.contest_comission_answer}
          </td>
          <td style={{ textAlign: "center", width: 200, cursor: "pointer" }}>
            <Button
              className="button"
              onClick={() => {
                history(`/admin/${grant.id}/project`);
              }}
            >
              Подробнее
            </Button>
          </td>
          <td style={{ textAlign: "center", width: 200, cursor: "pointer" }}>
            <Button className="button" onClick={() => onDeleteGrant(grant.id)}>
              Удалить
            </Button>
          </td>
        </tr>
      );
    });
  }, [grants, history, onDeleteGrant]);

  const renderPlaceholder = useMemo(() => {
    return (
      <tr>
        <td colSpan="5">
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            Загрузка
          </span>
        </td>
      </tr>
    );
  }, []);

  useEffect(() => {
    if (grants.length === 0) {
      getUserGrants();
    } else {
      return;
    }
  }, [grants.length]);

  return (
    <div className="admin-table">
      <div className="admin-table__head">
        <NavLink to="/expert_show">
          <Button className="admin-table__button">Эксперты</Button>
        </NavLink>
      </div>
      <Table className="admin-table__list" bordered hover>
        <thead>
          <tr>
            <th colSpan="5">
              <span style={{ float: "left" }}>Посмотреть список запросов</span>
              <span style={{ float: "right" }}>{grants.length}</span>
            </th>
          </tr>
        </thead>
        <tbody>{grants.length > 0 ? renderGrants : renderPlaceholder}</tbody>
      </Table>
    </div>
  );
};
