import { Button, Card, FormControl, FormLabel } from "react-bootstrap";
import "./styles.scss";
import { useCallback, useEffect, useState } from "react";
import { getExpert } from "../api";
import { useNavigate, useParams } from "react-router";
import { updateExpert } from "../api";

const ExpertEdit = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [expert, setExpert] = useState({
    user_email: "",
    user_password: "",
    first_name: "",
    last_name: "",
  });
  const [isValid, setIsValid] = useState(false);

  const onFormSubmit = useCallback(() => {
    console.log(expert);

    updateExpert(params.id, expert)
      .then((response) => {
        if (response) {
          navigate("/expert_show");
        }
      })
      .catch((error) => console.log(error));
  }, [expert, navigate, params]);

  const onChangeField = useCallback(
    (event) => {
      setExpert({ ...expert, [event.target.name]: event.target.value });
    },
    [expert]
  );

  useEffect(() => {
    getExpert(params.id)
      .then(({ data }) => {
        const { user_email, user_password, first_name, last_name } =
          data.expert;

        setExpert({ user_email, user_password, first_name, last_name });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [params]);

  useEffect(() => {
    const isEmpty = Object.values(expert).some(
      (x) => x === null || x === "" || x === undefined
    );

    if (!isEmpty) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [expert]);

  if (expert) {
    return (
      <Card className="expert-edit">
        <div onSubmit={onFormSubmit} className="expert-edit__form">
          <div className="expert-edit__form-item">
            <FormLabel className="expert-edit__form-label">Email</FormLabel>
            <FormControl
              onChange={onChangeField}
              value={expert.user_email}
              className="expert-edit__form-input"
              name="user_email"
            />
          </div>
          <div className="expert-edit__form-item">
            <FormLabel className="expert-edit__form-label">Пароль</FormLabel>
            <FormControl
              onChange={onChangeField}
              name="user_password"
              value={expert.user_password}
              className="expert-edit__form-input"
            />
          </div>
          <div className="expert-edit__form-item">
            <FormLabel className="expert-edit__form-label">Фамилия</FormLabel>
            <FormControl
              onChange={onChangeField}
              name="first_name"
              value={expert.first_name}
              className="expert-edit__form-input"
            />
          </div>
          <div className="expert-edit__form-item">
            <FormLabel className="expert-edit__form-label">
              Имя Отчество
            </FormLabel>
            <FormControl
              onChange={onChangeField}
              name="last_name"
              value={expert.last_name}
              className="expert-edit__form-input"
            />
          </div>
          <Button disabled={!isValid} onClick={onFormSubmit}>
            Сохранить
          </Button>
        </div>
      </Card>
    );
  } else {
    return <div>Загрузка...</div>;
  }
};

export default ExpertEdit;
