import { useMemo } from "react";
import "./styles.scss";
import AdminExpertsAnswers from "./components/AdminExpertsAnswers";
import ExpertAnswer from "./components/ExpertAnswer";

const AdminExpertsNew = ({ records }) => {
  const renderRecordsMemo = useMemo(() => {
    return records.map((record) => {
      return <ExpertAnswer record={record} />;
    });
  }, [records]);

  return (
    <div className="admin-records">
      {renderRecordsMemo}
      <AdminExpertsAnswers records={records} />
    </div>
  );
};

export default AdminExpertsNew;
