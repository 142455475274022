import axios from "axios";

const defaultConfig = { headers: { "Content-Type": "application/json" } }

export const login = (params) => {
  return axios.post(
    "/api/auth/login",
    { ...params },
    defaultConfig
  );
}

export const getGrants = (id) => {
  return axios.get(`/api/expert/get_expert_grants/${id}`, defaultConfig)
}

export const getGrantData = (id) => {
  return axios.get(`/api/expert/get_expert_grant/${id}`, defaultConfig)
}

export const getProjectDownload = (project_description) => {
  return axios.get(`/api/file/download/${project_description}`, {
    responseType: "blob",
  })
}

export const getDiplomaDownload = (lector_speciality) => {
  return axios.get(`/api/file/download/${lector_speciality}`, {
    responseType: "blob",
  })
}

export const setGrantAnswers = (id, answers) => {
  return axios.post(`/api/expert/grant_update_answers/${id}`, { answers }, defaultConfig)
}