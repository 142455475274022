import { Form, InputGroup, FormControl } from "react-bootstrap";

const FormField = ({ id, name, type, value, label, onChangeHandler }) => {
  return (
    <div>
      <Form.Label htmlFor="basic-url">{label}</Form.Label>
      <InputGroup className="input">
        <FormControl
          id={id}
          type={type}
          name={name}
          value={value}
          onChange={onChangeHandler}
          className="expert-auth__field"
          aria-describedby="basic-addon3"
        />
      </InputGroup>
    </div>
  );
};

export default FormField;
