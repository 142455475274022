import { Routes, Route, Navigate } from "react-router-dom";
import { CreateNewGrant } from "../../pages/CreateNewGrant";
import { UserPermission } from "../../pages/UserPermission";
import { UserComission } from "../../pages/UserComission";
import { CreateGrants } from "../../pages/CreateGrants";
import { UserExperts } from "../../pages/UserExperts";
import { UserProject } from "../../pages/UserProject";
import { Profile } from "../../pages/Profile";
import { Grants } from "../../pages/Grants";
import React from "react";

const UserRoutes = () => {
  return (
    <Routes>
      <Route path="/authed" element={<Grants />} />
      <Route path="/" element={<Grants />} />
      <Route path="/create_grant" element={<CreateGrants />} />
      <Route path="/grants" element={<Grants />} />
      <Route path="/create_new_grant/:sum" element={<CreateNewGrant />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/user/:id/project" element={<UserProject />} />
      <Route path="/user/:id/permission" element={<UserPermission />} />
      <Route path="/user/:id/experts" element={<UserExperts />} />
      <Route path="/user/:id/comission" element={<UserComission />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default UserRoutes;
