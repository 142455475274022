import { useCallback, useState } from "react";
import { Card, FormControl, InputGroup } from "react-bootstrap";

const FormMail = ({ title, placeholder, value, name, onSetFormField }) => {
  const [inputValue, setInputValue] = useState(value);

  const onChangeInput = useCallback(
    (event) => {
      const target = event.target;

      setInputValue(target.value);

      onSetFormField(name, target.value);
    },
    [onSetFormField, name]
  );

  return (
    <Card className="form-card">
      <h4 className="form-card__title">{title}</h4>
      <InputGroup className="input-modal">
        <FormControl
          required
          type="text"
          value={inputValue}
          onChange={onChangeInput}
          id="basic-url"
          name={name}
          readOnly
          className="input-modal"
          placeholder={placeholder}
          aria-describedby="basic-addon3"
        />
      </InputGroup>
    </Card>
  );
};

export default FormMail;
