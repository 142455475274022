import { Card } from "react-bootstrap";

const FormInfo = () => {
  return (
    <Card className="form-card">
      <h4 className="form-card__title">БЮЛЛЕТЕНЬ</h4>
      <p className="form-card__text">
        для голосования по вопросам оценки заявки участника Поощрительной
        программы содействия развития профессиональных компетенций и организации
        просвещения в области медицины и экологии в рамках проекта БФ «ТЕФИДА»
      </p>
      <div className="form-card__info">
        <span className="form-card__text">Правила заполнения бюллетеней.</span>
        <span className="form-card__text">
          Голосующий вправе выбрать только один вариант голосования.
        </span>
        <span className="form-card__text">
          Бюллетень недействителен без подписи Эксперта (подпись - третий
          раздел)
        </span>
      </div>
    </Card>
  );
};

export default FormInfo;
