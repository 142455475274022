import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import { IoMdArrowBack } from "react-icons/io";
import { Button } from "react-bootstrap";

import { ProjectInfo, ProjectHead } from "./blocks";
import { getGrantData } from "../api";
import "./styles.scss";

const ExpertProject = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [grantInfo, setGrantInfo] = useState({});
  const [expertGrant, setExpertGrant] = useState({});

  const onNavigateMain = useCallback(() => {
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    getGrantData(id)
      .then(({ data }) => {
        setGrantInfo(data.grantInfo);
        setExpertGrant(data.grant);
      })
      .catch((error) => console.log(error));
  }, [id]);

  if (grantInfo && expertGrant) {
    return (
      <div className="expert-grant">
        <Button
          variant="outline-dark"
          onClick={onNavigateMain}
          className="expert-form__button"
        >
          <IoMdArrowBack />
          назад
        </Button>
        <ProjectHead expertGrant={expertGrant} />
        <ProjectInfo grant={grantInfo} />
      </div>
    );
  }
};

export default ExpertProject;
