import { Routes, Route, Navigate } from "react-router-dom";
import {
  ExpertCabinet,
  ExpertForm,
  ExpertProject,
  ExpertAnswers,
} from "pages/Expert";

const ExpertRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ExpertCabinet />} />
      <Route path="/authed" element={<ExpertCabinet />} />
      <Route path="/expert/:id/grant" element={<ExpertProject />} />
      <Route path="/expert-cabinet" element={<ExpertCabinet />} />
      <Route path="/expert/:id/form" element={<ExpertForm />} />
      <Route path="/expert/:id/answers" element={<ExpertAnswers />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default ExpertRoutes;
