import { useEffect, useMemo, useState } from "react";
import { Table } from "react-bootstrap";

import { TableHead, TableRow } from "./components";
import { getGrants } from "pages/Expert/api";
import "./styles.scss";
import { FILTER_MODS } from "../../config";

const GrantsList = ({ filterMode }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [grants, setGrants] = useState([]);
  const [filteredGrants, setFilteredGrants] = useState([]);

  const renderGrantsMemo = useMemo(() => {
    return filteredGrants.map((grant) => {
      return <TableRow key={grant.id} grant={grant} />;
    });
  }, [filteredGrants]);

  useEffect(() => {
    getGrants(userData.data.id)
      .then(({ data }) => {
        setGrants(data.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userData.data.id]);

  useEffect(() => {
    if (filterMode === FILTER_MODS.answered) {
      const answeredGrants = grants.filter((item) => Boolean(item.answers));

      setFilteredGrants(answeredGrants);
    } else if (filterMode === FILTER_MODS.unanswered) {
      const unansweredGrants = grants.filter((item) => item.answers === null);

      setFilteredGrants(unansweredGrants);
    } else {
      setFilteredGrants(grants);
    }
  }, [filterMode, grants]);

  return (
    <Table className="grants-table" bordered hover>
      <TableHead grantsLength={filteredGrants.length} />
      <tbody>{renderGrantsMemo}</tbody>
    </Table>
  );
};

export default GrantsList;
