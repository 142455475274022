import React, { useEffect, useState } from 'react';
import { Button, Form } from "react-bootstrap";
import { useParams } from "react-router";
import axios from "axios";
import fileDownload from "js-file-download";

export const UserExperts = () => {
    const params = useParams();
    const [grant, setGrant] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getProjectData();
    }, [params.id])

    const getProjectData = async () => {
        axios.get(`/api/grant//get_grant/${params.id}`, { headers: { ['Content-Type']: 'application/json' } })
            .then(function (response) {
                setGrant(response.data[0]);
                setLoading(!loading);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const downloadExpertsHandler = (file) => {
        axios.get(`/api/file/download/${file}`, { responseType: 'blob' })
            .then(function (response) {
                console.log(response);
                fileDownload(response.data, file);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    if (loading) return <div className='create-page' style={{ minHeight: 250, marginTop: 30 }}><div className='data-show' style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <h5 style={{ fontWeight: 700 }}>ОТВЕТ ЭКСПЕРТОВ</h5></div><div style={{ marginTop: '5%' }} className="spinner-border text-success" role="status"></div></div>


    return (
        <div className='create-page' style={{ minHeight: 250, marginTop: 30 }}>
            <div className='data-show' style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <h5 style={{ fontWeight: 700 }}>ОТВЕТ ЭКСПЕРТОВ</h5>
                {
                    grant.expert_answer === 'Ожидает' && <p style={{ textAlign: 'center', marginTop: 48, marginBottom: 60 }}>Проект на расмотрении экспертного совета.
                        Ответ придет в течение недели</p>
                }

                {
                    grant.expert_answer === 'Отклонено' && <p style={{ textAlign: 'center', marginTop: 48, marginBottom: 60 }}>Вам отказано. Просим вас проверить проект и попытаться еще раз</p>
                }
                {
                    grant.expert_answer === 'Принято' && <div style={{ textAlign: 'center', marginTop: 48, marginBottom: 60 }}>
                        Проект принят экспертной комиссией
                    </div>
                }

                {
                    grant.expert_answer_1_file !== '' && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}><p style={{ marginTop: 28 }}>Одобрено экспертами #1</p><Button onClick={() => { downloadExpertsHandler(grant.expert_answer_1_file) }} style={{ marginBottom: 20 }} className='file-input'>Скачать файл</Button>
                    </div>
                }
                {
                    grant.expert_answer_2_file !== '' && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}><p style={{ marginTop: 28 }}>Одобрено экспертами #2</p><Button onClick={() => { downloadExpertsHandler(grant.expert_answer_2_file) }} style={{ marginBottom: 20 }} className='file-input'>Скачать файл</Button>
                    </div>
                }
                {
                    grant.expert_answer_3_file !== '' && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}><p style={{ marginTop: 28 }}>Одобрено экспертами #3</p><Button onClick={() => { downloadExpertsHandler(grant.expert_answer_3_file) }} style={{ marginBottom: 20 }} className='file-input'>Скачать файл</Button>
                    </div>
                }
            </div>
        </div>
    )
}
