import { Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";

import logo from "images/logo.svg";
import logo_text from "images/logo_text.svg";
import { AuthContext } from "context/AuthContext";
import FormField from "./FormField";

import "./styles.scss";
import { login } from "../Expert/api";

export const ExpertAuth = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(false);

  const [user_data, setUserData] = useState({
    user_email: "",
    user_password: "",
  });

  const changeHandler = (event) => {
    setUserData({ ...user_data, [event.target.name]: event.target.value });
  };

  const loginHandler = () => {
    setLoading(true);
    setError("");

    login(user_data)
      .then((response) => {
        setLoading(false);

        auth.login(
          response.data.token,
          response.data.data,
          response.data.is_admin,
          response.data.is_expert
        );

        navigate("/authed");

        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);

        setError(error.response.data.message);

        console.log(error);
      });
  };

  return (
    <div className="expert-auth">
      <div className="expert-auth__head">
        <img className="expert-auth__logo" src={logo} alt="" />
        <img className="expert-auth__logo-text" src={logo_text} alt="" />
      </div>
      <div className="expert-auth__form">
        <FormField
          type="text"
          id="basic-url"
          name="user_email"
          label="Введите логин"
          value={user_data.username}
          onChangeHandler={changeHandler}
        />
        <FormField
          id="basic-url"
          type="password"
          name="user_password"
          label="Введите пароль"
          value={user_data.password}
          onChangeHandler={changeHandler}
        />
      </div>
      {!isLoading ? (
        <Button onClick={loginHandler} className="button">
          Войти
        </Button>
      ) : (
        <Spinner />
      )}
      {error && <span className="expert-auth__error">{error}</span>}
    </div>
  );
};

export default ExpertAuth;
