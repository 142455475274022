import { AdminPermission } from "../../pages/admin/AdminPermission";
import { AdminComission } from "../../pages/admin/AdminComission";
import { Routes, Route, Navigate } from "react-router-dom";
import { AdminProject } from "../../pages/admin/AdminProject";
import { AdminExperts } from "pages/admin/AdminExperts/index";
import { AllGrants } from "pages/admin/AllGrants";
import { ExpertAnswers } from "pages/Expert";
import ExpertsShow from "pages/admin/ExpertsEdit/index";
import ExpertEdit from "pages/admin/ExpertsEdit/edit";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AllGrants />} />
      <Route path="/authed" element={<AllGrants />} />
      <Route path="/admin/:id/project" element={<AdminProject />} />
      <Route path="/admin/:id/permission" element={<AdminPermission />} />
      <Route path="/admin/:id/experts" element={<AdminExperts />} />
      <Route path="/admin/:id/experts" element={<AdminExperts />} />

      <Route path="/admin/:id/comission" element={<AdminComission />} />
      <Route path="*" element={<Navigate to="/authed" />} />
      <Route path="/expert/:id/answers" element={<ExpertAnswers />} />
      <Route path="/expert_show" element={<ExpertsShow />} />
      <Route path="/expert/:id/edit" element={<ExpertEdit />} />
    </Routes>
  );
};

export default AdminRoutes;
