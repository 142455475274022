import React, { useState } from "react";
import axios from "axios";
import "./styles.scss";

const CreateAccount = () => {
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    user_email: "",
    user_password: "",
    is_admin: false,
    is_expert: false,
  });

  const changehandler = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const [status, setStatus] = useState("");

  const confirm = async () => {
    await axios
      .post(
        "/api/auth/register",
        { ...data },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(function (response) {
        console.log(response);

        setStatus(response.data);
      })
      .catch(function (error) {
        console.log(error);

        setStatus(error.message);
      });
  };

  return (
    <div className="create-account" style={{ maxWidth: 500 }}>
      <input
        name="first_name"
        placeholder="first_name"
        value={data.first_name}
        onChange={changehandler}
      />
      <input
        name="last_name"
        placeholder="last_name"
        value={data.last_name}
        onChange={changehandler}
      />
      <input
        name="user_email"
        placeholder="user_email"
        value={data.user_email}
        onChange={changehandler}
      />
      <input
        name="user_password"
        placeholder="user_password"
        value={data.user_password}
        onChange={changehandler}
      />
      <label className="create-account__block">
        <input
          type="checkbox"
          name="is_admin"
          placeholder="user_password"
          onChange={() => {
            setData({ ...data, is_admin: !data.is_admin });
          }}
        />
        <span>ADMIN</span>
      </label>
      <label className="create-account__block">
        <input
          type="checkbox"
          name="is_expert"
          onChange={() => {
            setData({ ...data, is_expert: !data.is_expert });
          }}
        />
        <span>EXPERT</span>
      </label>

      <button onClick={confirm}>Создать</button>
      <div>{`STATUS: ${status}`}</div>
    </div>
  );
};

export default CreateAccount;
