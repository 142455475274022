import React, { useEffect, useState } from "react";
import { Navbar, Nav, NavItem, Button } from "react-bootstrap";
import back from "../../images/utils/back.svg";
import close from "../../images/utils/close.png";
import menu from "../../images/utils/menu.png";
import { NavLink, useNavigate } from "react-router-dom";
import { MdClose, MdMenu } from "react-icons/md";

export const SideNav = () => {
  const user_data = JSON.parse(localStorage.getItem("userData"));
  const is_admin = user_data.data.is_admin;
  const [page, setPage] = useState(0);
  const [visible, setVisible] = useState(false);
  const [pathname, setPathname] = useState(1);
  const [win, setWin] = useState(window.innerWidth);

  const activePage = () => {
    const act = window.location.pathname;
    if (act.includes("admin")) {
      const path = act.match(/\d+/g).toString();
      setPathname(parseInt(path));
    }
    if (act.includes("user")) {
      const path = act.match(/\d+/g).toString();
      setPathname(parseInt(path));
    }
    if (!act.includes("admin") || !act.includes("user")) {
      setVisible(true);
    }
    if (act.includes("project")) {
      setPage(0);
      setVisible(false);
    } else if (act.includes("experts")) {
      setPage(1);
      setVisible(false);
    } else if (act.includes("comission")) {
      setPage(2);
      setVisible(false);
    } else if (act.includes("permission")) {
      setPage(3);
      setVisible(false);
    }
  };

  useEffect(() => {
    activePage();
  });

  const history = useNavigate();

  const [isOpen, setisOpen] = useState(false);

  return (
    <div hidden={visible}>
      {win > 500 ? (
        <div style={{ marginTop: 30 }}>
          <Button
            className="back-button"
            onClick={() => {
              history("/authed");
              setVisible(!visible);
            }}
          >
            <img alt="" src={back} /> Вернуться назад
          </Button>
          <div className="side-nav">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                className={page === 0 ? "item-active" : "item-nonactive"}
                htmlFor="indicator-1"
              >
                <NavLink
                  to={
                    is_admin
                      ? `/admin/${pathname}/project`
                      : `/user/${pathname}/project`
                  }
                >
                  <input
                    id="indicator-1"
                    name="page"
                    value="1"
                    type="radio"
                    defaultChecked={true}
                    onChange={() => {
                      setPage(0);
                    }}
                  />
                </NavLink>
                Проекты
              </label>
              <label
                className={page === 1 ? "item-active" : "item-nonactive"}
                htmlFor="indicator-2"
              >
                <NavLink
                  to={
                    is_admin
                      ? `/admin/${pathname}/experts`
                      : `/user/${pathname}/experts`
                  }
                >
                  <input
                    id="indicator-2"
                    name="page"
                    value="2"
                    type="radio"
                    onChange={() => {
                      setPage(1);
                    }}
                  />
                </NavLink>
                Экспертный совет
              </label>
              <label
                className={page === 2 ? "item-active" : "item-nonactive"}
                htmlFor="indicator-3"
              >
                <NavLink
                  to={
                    is_admin
                      ? `/admin/${pathname}/comission`
                      : `/user/${pathname}/comission`
                  }
                >
                  <input
                    id="indicator-3"
                    name="page"
                    value="3"
                    type="radio"
                    onChange={() => {
                      setPage(2);
                    }}
                  />
                </NavLink>
                Конкурсная комиссия
              </label>
              <label
                className={page === 3 ? "item-active" : "item-nonactive"}
                style={{ marginBottom: 12 }}
                htmlFor="indicator-4"
              >
                <NavLink
                  to={
                    is_admin
                      ? `/admin/${pathname}/permission`
                      : `/user/${pathname}/permission`
                  }
                >
                  <input
                    id="indicator-4"
                    name="page"
                    value="4"
                    type="radio"
                    onChange={() => {
                      setPage(3);
                    }}
                  />
                </NavLink>
                Договор
              </label>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Button
            className="menu-button"
            onClick={() => {
              setisOpen(!isOpen);
            }}
          >
            <p>Меню</p>
            {isOpen ? <MdClose size={16} /> : <MdMenu size={16} />}
          </Button>
          <div
            style={{ marginTop: 40 }}
            className="mobile-sidebar"
            hidden={!isOpen}
          >
            <div className="side-nav" style={{ marginLeft: 10 }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  className="item-nonactive"
                  style={{ marginLeft: 12 }}
                  onClick={() => {
                    history("/");
                    setVisible(!visible);
                    setisOpen(!isOpen);
                  }}
                >
                  Вернуться назад
                </label>
                <label
                  className={page === 0 ? "item-active" : "item-nonactive"}
                  htmlFor="indicator-1"
                >
                  <NavLink
                    to={
                      is_admin
                        ? `/admin/${pathname}/project`
                        : `/user/${pathname}/project`
                    }
                  >
                    <input
                      id="indicator-1"
                      name="page"
                      value="1"
                      type="radio"
                      defaultChecked={true}
                      onChange={() => {
                        setPage(0);
                        setisOpen(!isOpen);
                      }}
                    />
                  </NavLink>
                  Проекты
                </label>
                <label
                  className={page === 1 ? "item-active" : "item-nonactive"}
                  htmlFor="indicator-2"
                >
                  <NavLink
                    to={
                      is_admin
                        ? `/admin/${pathname}/experts`
                        : `/user/${pathname}/experts`
                    }
                  >
                    <input
                      id="indicator-2"
                      name="page"
                      value="2"
                      type="radio"
                      onChange={() => {
                        setPage(1);
                        setisOpen(!isOpen);
                      }}
                    />
                  </NavLink>
                  Экспертный совет
                </label>
                <label
                  className={page === 2 ? "item-active" : "item-nonactive"}
                  htmlFor="indicator-3"
                >
                  <NavLink
                    to={
                      is_admin
                        ? `/admin/${pathname}/comission`
                        : `/user/${pathname}/comission`
                    }
                  >
                    <input
                      id="indicator-3"
                      name="page"
                      value="3"
                      type="radio"
                      onChange={() => {
                        setPage(2);
                        setisOpen(!isOpen);
                      }}
                    />
                  </NavLink>
                  Конкурсная комиссия
                </label>
                <label
                  className={page === 3 ? "item-active" : "item-nonactive"}
                  style={{ marginBottom: 12 }}
                  htmlFor="indicator-4"
                >
                  <NavLink
                    to={
                      is_admin
                        ? `/admin/${pathname}/permission`
                        : `/user/${pathname}/permission`
                    }
                  >
                    <input
                      id="indicator-4"
                      name="page"
                      value="4"
                      type="radio"
                      onChange={() => {
                        setPage(3);
                        setisOpen(!isOpen);
                      }}
                    />
                  </NavLink>
                  Договор
                </label>
              </div>
            </div>
          </div>
        </>
      )}
    </div>

    // <div style={{marginTop:-30}}>
    //     <Button className='back-button' onClick={()=>{history('/'); setVisible(!visible);}}><img src={back} alt=''/> Вернуться назад</Button>
    //     <div className='side-nav'>
    //         <div style={{display:'flex', flexDirection:'column'}}>
    //             <label className={page === 0 ? ('item-active') : ('item-nonactive')} htmlFor='indicator-1'><NavLink to={is_admin ? (`/admin/${pathname}/project`) : (`/user/${pathname}/project`)}><input id='indicator-1' name='page' value='1' type='radio' defaultChecked={true} onChange={()=>{setPage(0)}}/></NavLink>Проекты</label>
    //             <label className={page === 1 ? ('item-active') : ('item-nonactive')} htmlFor='indicator-2'><NavLink to={is_admin ? (`/admin/${pathname}/experts`) : (`/user/${pathname}/experts`)}><input id='indicator-2' name='page' value='2' type='radio' onChange={()=>{setPage(1)}}/></NavLink>Экспертный совет</label>
    //             <label className={page === 2 ? ('item-active') : ('item-nonactive')} htmlFor='indicator-3'><NavLink to={is_admin ? (`/admin/${pathname}/comission`) : (`/user/${pathname}/comission`)}><input id='indicator-3' name='page' value='3' type='radio' onChange={()=>{setPage(2)}}/></NavLink>Конкурентная комиссия</label>
    //             <label className={page === 3 ? ('item-active') : ('item-nonactive')} style={{marginBottom:12}} htmlFor='indicator-4'><NavLink to={is_admin ? (`/admin/${pathname}/permission`) : (`/user/${pathname}/permission`)}><input id='indicator-4' name='page' value='4' type='radio' onChange={()=>{setPage(3)}}/></NavLink>Договор</label>
    //         </div>
    //     </div>
    // </div>
  );
};
