import axios from "axios";
import { useParams } from "react-router";
import { Spinner } from "react-bootstrap";
import React, { useState, useEffect, useMemo, useCallback } from "react";

export const AdminPermission = () => {
  const params = useParams();

  const [project, setProject] = useState({});
  const [contract, setContract] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");
  const [isLoadingFile, setLoadingFile] = useState(false);

  const [file, setFile] = useState({
    project_contract: null,
  });

  const getInitialData = async () => {
    await axios
      .get(`/api/grant/get_grant/${params.id}`, {
        headers: { "Content-Type": "application/json" },
      })
      .then(function (response) {
        console.log("initial response", response);

        setProject(response.data[0]);
        setContract(response.data[0].project_contract);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onDeleteFile = useCallback(() => {
    axios
      .post(
        `/api/admin/grant_update_project_contract/${params.id}`,
        { project_contract: "" },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        setProject({});
        setContract("");
      })
      .catch((error) => {
        console.log(error);
      });
  }, [params.id]);

  const uploadHandler = async () => {
    const formData = new FormData();

    formData.append(
      "MyFile",
      file.project_contract,
      file.project_contract.name
    );

    setLoadingFile(true);

    await axios
      .post("/api/file/upload", formData, {
        headers: { "Content-Type": "application/json" },
      })
      .then(async function (response) {
        await axios
          .post(
            `/api/admin/grant_update_project_contract/${params.id}`,
            { project_contract: response.data.filename },
            { headers: { "Content-Type": "application/json" } }
          )
          .then(function (response) {
            console.log(response);
            getInitialData();
            setLoadingFile(false);
          })
          .catch(function (error) {
            console.log(error);
            setLoadingFile(false);
          });
      })
      .catch(function (error) {
        console.log(error);
        setLoadingFile(false);
      });
  };

  const onFileChange = useCallback(
    (event) => {
      setFile({ ...file, project_contract: event.target.files[0] });
    },
    [file]
  );

  const renderFileInput = useMemo(() => {
    if (contract === "") {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
          <label
            className="file-input"
            name="experts_approved_1"
            htmlFor="experts_approved_2"
            style={{ cursor: "pointer" }}
          >
            <input
              id="experts_approved_2"
              type="file"
              name="project_contract"
              style={{ display: "none" }}
              onChange={onFileChange}
            />
            Прикрепить файл
          </label>
          {isLoadingFile && (
            <Spinner animation="border" size="sm" color="green" role="status" />
          )}
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            marginTop: 29,
          }}
        >
          <button onClick={onDeleteFile} className="file-input">
            Удалить файл
          </button>
          <a
            href={downloadUrl}
            download={project.project_contract}
            className="file-input"
            style={{ textDecoration: "none" }}
          >
            Скачать файл
          </a>
        </div>
      );
    }
  }, [
    contract,
    downloadUrl,
    onDeleteFile,
    onFileChange,
    isLoadingFile,
    project.project_contract,
  ]);

  useEffect(() => {
    if (file.project_contract !== null) {
      uploadHandler();

      setFile({ ...file, project_contract: null });
    }

    if (project === "") {
      getInitialData();
    }
  }, [project, file]);

  useEffect(() => {
    if (project.project_contract) {
      axios
        .get(`/api/file/download/${project.project_contract}`, {
          responseType: "blob",
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob.data);

          setDownloadUrl(url);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [project]);

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <div className="admin-page" style={{ marginTop: 30 }}>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <h5 style={{ fontWeight: 700 }}>ДОГОВОР НА ВЫДАЧУ ГРАНТА</h5>
      </div>
      <h6 style={{ marginTop: 60, fontWeight: 700 }}>
        Договор на выдачу гранта
      </h6>
      {renderFileInput}
      {contract !== "" && (
        <h5
          style={{
            fontWeight: 600,
            fontSize: "16px",
            marginTop: 29,
            color: project.user_agreement ? "#198754" : "#fd7e14",
          }}
        >
          {project.user_agreement
            ? "Пользователь принял условия договора"
            : "Пользователь еще не принял условия договора"}
        </h5>
      )}
      <h5
        style={{
          fontWeight: 400,
          fontSize: "12px",
          color: "red",
          marginTop: 29,
        }}
      >
        Клиент сможет получить доступ к договору только после принятия
        конкурсной комиссии.
      </h5>
    </div>
  );
};
