import { useEffect, useContext } from "react";
import { useQuery } from '../hooks/query.hook';
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const HandleAuth = ({ children }) => {
    const auth = useContext(AuthContext);
    const history = useNavigate();

    const handleAuth = (code) => {
        axios.post('/api/auth/esiacode', { code })
            .then(function (response) {
                auth.login(response.data.token, response.data.data, response.data.is_admin);
                history('/authed');
                window.location.reload();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const query = useQuery();

    useEffect(() => {
        let code = query.get('code');
        if (code) {
            console.log(code);
            handleAuth(code);

            query.delete('code');
            history({
                search: query.toString(),
            });
        }
    }, []);

    return children;
};