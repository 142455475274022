import React, { Fragment } from "react";
import { useRoutes } from "./routes/routes";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import { Header } from "./components/utils/Header";
import { Footer } from "./components/utils/Footer";
import { useAuth } from "./hooks/auth.hook";
import { SideNav } from "./components/utils/SideNav";

function App() {
  const { token, login, logout, admin, expert, ready } = useAuth();
  const isAuthenticated = !!token;
  const isAdmin = admin;
  const isExpert = expert
  const routes = useRoutes({ isAuthenticated, isAdmin, isExpert });

  if (!ready) {
    return <></>;
  }

  return (
    <Fragment>
      <div className="global">
        <AuthContext.Provider
          value={{ token, login, logout, isAuthenticated, isAdmin }}
        >
          <Router>
            <Header />
            <div
              style={{ display: "flex", justifyContent: "center" }}
            >
              {isAuthenticated && <SideNav />}
              {routes}
            </div>
            <Footer />
          </Router>
        </AuthContext.Provider>
      </div>
    </Fragment>
  );
}

export default App;
