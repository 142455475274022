import React, { useEffect, useState, useCallback } from "react";
import { Form } from "react-bootstrap";
import { AdminExpertsNew, AdminOldLogic } from "./blocks";
import { useParams } from "react-router";
import axios from "axios";

export const AdminExperts = () => {
  const params = useParams();

  const [isNewLogic, setNewLogic] = useState();

  const [expertRecords, setExpertsRecords] = useState();
  const [data, setData] = useState({
    expert_answer: "",
  });
  const [initial, setInitial] = useState("");
  const [project, setProject] = useState({});

  const changeHandler = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const getInitialData = useCallback(async () => {
    await axios
      .get(`/api/grant/get_grant/${params.id}`, {
        headers: { "Content-Type": "application/json" },
      })
      .then(function (response) {
        setProject(response.data[0]);

        setData({ ...data, expert_answer: response.data[0].expert_answer });

        setInitial(response.data[0].expert_answer);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data, params.id]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const confirmHandler = async () => {
    await axios
      .post(
        `/api/admin/grant_update_expert/${params.id}`,
        { ...data },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(function (response) {
        console.log(response);

        getInitialData();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get(`/api/expert/get_experts_grants/${params.id}`, {
        headers: { "Content-Type": "application/json" },
      })
      .then(({ data }) => {
        if (data.grants.length > 0) {
          setNewLogic(true);
        } else {
          setNewLogic(false);
        }

        setExpertsRecords(data.grants);
      })
      .catch((error) => {
        setNewLogic(false);

        console.log(error);
      });
  }, [params.id]);

  useEffect(() => {
    if (data.expert_answer === "") {
      getInitialData();
    }

    if (data.expert_answer !== initial) {
      confirmHandler();
    }
  }, [confirmHandler, data, getInitialData, initial]);

  if (isNewLogic === false || isNewLogic === true) {
    return (
      <div className="admin-page" style={{ marginTop: 30 }}>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <h5 style={{ fontWeight: 700 }}>ОТВЕТ ЭКСПЕРТОВ</h5>
          <h6 style={{ marginTop: 20, fontWeight: 700 }}>Статус заявки</h6>
          <Form.Select
            name="expert_answer"
            value={data.expert_answer}
            onChange={changeHandler}
            aria-label="Default select example"
            style={{ borderRadius: 25 }}
          >
            <option value="Ожидает">Ожидает</option>
            <option value="Отклонено">Отклонено</option>
            <option value="Принято">Принято</option>
          </Form.Select>

          {isNewLogic ? (
            <AdminExpertsNew records={expertRecords} />
          ) : (
            <AdminOldLogic getInitialData={getInitialData} project={project} />
          )}
        </div>
      </div>
    );
  }
};
