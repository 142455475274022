import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { useParams } from "react-router";
import React, { useCallback, useEffect, useMemo, useState } from "react";

export const UserPermission = () => {
  const params = useParams();

  const [grant, setGrant] = useState({});
  const [loading, setLoading] = useState(true);
  const [isAgree, setIsAgree] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [isVisibleModal, setVisibleModal] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);
  const [commissionStatus, setCommissionStatus] = useState("");

  const getProjectData = async () => {
    axios
      .get(`/api/grant//get_grant/${params.id}`, {
        headers: { ["Content-Type"]: "application/json" },
      })
      .then(function (response) {
        console.log("response.data[0]", response.data[0]);
        setGrant(response.data[0]);
        setLoading(!loading);
        setUserAgreement(response.data[0].user_agreement);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onChangeAgree = useCallback(() => setIsAgree(!isAgree), [isAgree]);

  const onChangeVisibleModal = useCallback(
    (event) => {
      if (!isVisibleModal) {
        event.stopPropagation();
      }

      setVisibleModal(!isVisibleModal);
    },
    [isVisibleModal]
  );

  const onUpdateAgreement = useCallback(() => {
    axios
      .post(
        `/api/grant/grant_update_user_agreement/${params.id}`,
        { user_agreement: isAgree },
        { headers: { ["Content-Type"]: "application/json" } }
      )
      .then((response) => {
        setUserAgreement(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [isAgree, params.id]);

  const agreementBlockMemo = useMemo(() => {
    return (
      <div style={{ marginTop: 48 }}>
        <div style={{ display: "flex", alignItems: "baseline", gap: 10 }}>
          <input
            type="checkbox"
            name="is-agreement"
            id="is-agreement-checkbox"
            placeholder="user_password"
            onChange={onChangeAgree}
          />
          <span>
            Нажимая на данную кнопку, вы соглашаетесь с &nbsp;
            <span
              onClick={onChangeVisibleModal}
              style={{
                color: "#0d6efd",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              условиями договора
            </span>
            &nbsp; оферты о предоставления гранта
          </span>
        </div>
        <Button
          variant="primary"
          disabled={!isAgree}
          style={{ marginTop: 20 }}
          onClick={onUpdateAgreement}
        >
          Cогласие с договором
        </Button>
      </div>
    );
  }, [onChangeAgree, onChangeVisibleModal, isAgree, onUpdateAgreement]);

  const confirmedMemoBlock = useMemo(() => {
    return (
      <p style={{ textAlign: "center", marginTop: 48, marginBottom: 48 }}>
        Вы приняли условия договора
      </p>
    );
  }, []);

  const modalMemo = useMemo(() => {
    return (
      <Modal
        dialogClassName="modal-10w"
        className="modal"
        show={isVisibleModal}
        onHide={onChangeVisibleModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Условия договора</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Настоящий Договор является Офертой и заключен на основании Положения
            о пожертвованиях (грантах) Благотворительного фонда "Фонд помощи
            системе здравоохранения и экологии ТЕФИДА", в рамках
            Благотворительной программы «Образование и просвещение». Фонд
            предоставляет денежные средства (далее - пожертвование) для
            финансового обеспечения реализации проекта Получателя в виде
            финансирования мероприятия или в виде компенсации затрат на
            проведение мероприятия, а Получатель обязуется принять пожертвование
            и распорядиться им исключительно в соответствии с целями, условиями
            и в порядке, закрепленными Договором. Акцепт Оферты Получателем —
            заключение договора путем полного и безоговорочного принятия
            Получателем условий договора, размещенного на специализированной
            электронной площадке на официальном сайте в сети интернет
            http://grands.tefida.world/, посредством подписания догвоора в
            электронном виде с использованием Единой система идентификации и
            аутентификации (ЕСИА) портала государственных услуг
            https://esia.gosuslugi.ru/login/
          </p>
        </Modal.Body>
      </Modal>
    );
  }, [isVisibleModal, onChangeVisibleModal]);

  useEffect(() => {
    if (commissionStatus === "Принято") {
      axios
        .get(`/api/file/download/${grant.project_contract}`, {
          responseType: "blob",
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob.data);

          setDownloadUrl(url);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [commissionStatus, grant.project_contract]);

  useEffect(() => {
    getProjectData();
  }, [params.id]);

  useEffect(() => {
    if (grant.contest_comission_answer) {
      setCommissionStatus(grant.contest_comission_answer);
    }
  }, [grant.contest_comission_answer]);

  if (loading)
    return (
      <div className="create-page" style={{ minHeight: 250, marginTop: 30 }}>
        <div
          className="data-show"
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <h5 style={{ fontWeight: 700 }}>ДОГОВОР НА ВЫДАЧУ ГРАНТА</h5>
        </div>
        <div
          style={{ marginTop: "5%" }}
          className="spinner-border text-success"
          role="status"
        ></div>
      </div>
    );

  return (
    <div className="create-page" style={{ minHeight: 250, marginTop: 30 }}>
      <div
        className="data-show"
        style={{ display: "flex", flexDirection: "column", width: "100%" }}
      >
        <h5 style={{ fontWeight: 700 }}>ДОГОВОР НА ВЫДАЧУ ГРАНТА</h5>
      </div>
      {commissionStatus !== "Принято" && (
        <p style={{ textAlign: "center", marginTop: 48, marginBottom: 94 }}>
          {commissionStatus === "Ожидает" &&
            "Проект на расмотрении совета. Ответ придет в течение недели"}
          {commissionStatus === "Отклонено" &&
            "Вам отказано. Просим вас проверить проект и попытаться еще раз"}
        </p>
      )}
      {commissionStatus === "Принято" && grant.project_contract === "" && (
        <p style={{ textAlign: "center", marginTop: 48, marginBottom: 94 }}>
          Договор по вашему гранту еще не готов. Проверьте позже.
        </p>
      )}
      {commissionStatus === "Принято" && grant.project_contract !== "" && (
        <>
          <h6 style={{ marginTop: 60, fontWeight: 700 }}>
            Договор на выдачу гранта
          </h6>

          <a
            href={downloadUrl}
            className="file-input"
            download={grant.project_contract}
            style={{ textDecoration: "none", marginTop: 29 }}
          >
            Скачать файл
          </a>
        </>
      )}

      {!userAgreement &&
        commissionStatus === "Принято" &&
        grant.project_contract !== "" &&
        agreementBlockMemo}
      {userAgreement &&
        commissionStatus === "Принято" &&
        grant.project_contract !== "" &&
        confirmedMemoBlock}

      {modalMemo}
    </div>
  );
};
