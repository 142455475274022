import { useCallback, useMemo } from "react";
import { Card } from "react-bootstrap";

const FormRadio = ({ description, options, name, setFormData }) => {
  const onChangeInput = useCallback(
    (event) => {
      const target = event.target;

      setFormData(name, target.value);
    },
    [name, setFormData]
  );

  const renderRadioGroup = useMemo(() => {
    return options.map((option, index) => {
      return (
        <label key={index} className="form-card__radio">
          <input
            type="radio"
            name={name}
            value={option.value}
            onChange={onChangeInput}
            className="form-card__radio-input"
          />
          <span className="form-card__radio-title">{option.label}</span>
        </label>
      );
    });
  }, [options, onChangeInput, name]);

  return (
    <Card className="form-card">
      <p className="form-card__text">{description}</p>
      <div className="form-card__info">{renderRadioGroup}</div>
    </Card>
  );
};

export default FormRadio;
