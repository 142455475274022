import React from 'react';
import { Button } from 'react-bootstrap';
import back from "../images/utils/back.svg";
import { useNavigate } from "react-router-dom";

export const CreateGrants = () => {
    const history = useNavigate();

    return (
        <div className='create-grant'>
            <Button className='back-button' onClick={() => { history('/'); }}><img src={back} alt='' /> Вернуться назад</Button>
            <h2>ВЫБЕРИТЕ ГРАНТ</h2>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 40 }}>
                <div className='grant-item'>
                    <div>
                        <h3>Для</h3>
                        <p>Специалистов</p>
                    </div>
                    <Button className='button' onClick={() => { history('/create_new_grant/0') }}>Выбрать</Button>
                </div>
                <div className='grant-item'>
                    <div>
                        <h3>Для</h3>
                        <p>Организаций</p>
                    </div>
                    <Button className='button' onClick={() => { history('/create_new_grant/1') }}>Выбрать</Button>
                </div>
            </div>

        </div>

    );
}
