import { useNavigate, useParams } from "react-router";
import { Button, Card } from "react-bootstrap";
import { useCallback, useMemo } from "react";

import "./styles.scss";

const ProjectHead = ({ expertGrant }) => {
  const navigate = useNavigate();
  const params = useParams();

  const onFormNavigate = useCallback(() => {
    navigate(
      expertGrant.answers
        ? `/expert/${params.id}/answers`
        : `/expert/${params.id}/form`
    );
  }, [navigate, params, expertGrant]);

  const buttonTitleMemo = useMemo(() => {
    return expertGrant.answers ? "Смотреть ответ" : "Ответить на заявку";
  }, [expertGrant]);

  return (
    <Card className="project-head">
      <Button className="button project-head__button" onClick={onFormNavigate}>
        {buttonTitleMemo}
      </Button>
    </Card>
  );
};

export default ProjectHead;
