import CreateAccount from "../../pages/admin/CreateAccount/index.jsx";
import { Routes, Route, Navigate } from "react-router-dom";
import { CreateNewGrant } from "../../pages/CreateNewGrant.js";
import { AdminAuth } from "../../pages/admin/AdminAuth.js";
import { HandleAuth } from "../../pages/HandleAuth.js";
import { Main } from "../../pages/Main.js";
import ExpertAuth from "../../pages/Auth/index.jsx";

const CommonRoutes = () => {
  return (
    <Routes>
      <Route path="/create_account" element={<CreateAccount />} />
      <Route path="/auth" element={<ExpertAuth />} />
      <Route
        path="/"
        element={
          <HandleAuth>
            <Main />
          </HandleAuth>
        }
      />
      <Route path="/create_new_grant/:sum" element={<CreateNewGrant />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default CommonRoutes;
