import { Card } from "react-bootstrap";
import "./styles.scss";

const FormAnswer = ({ answers }) => {
  return (
    <Card className="form-card form-answers">
      <h3 className="form-answers__title">Ответ по заявке</h3>
      <div className="form-answers__content">
        <div className="form-answers__item">
          <span className="form-answers__item-title">Email:</span>
          <span className="form-answers__item-value">{answers.email}</span>
        </div>
        <div className="form-answers__item">
          <span className="form-answers__item-title">ФИО:</span>
          <span className="form-answers__item-value">{answers.user_name}</span>
        </div>
        <div className="form-answers__item">
          <span className="form-answers__item-title">
            Проект соответствуют целям и задачам Поощрительной программы.
            Участник проекта отвечает требованиям, предусмотренным пунктом
            1.3.Положения о пожертвованиях(грантах) Поощрительной программы
          </span>
          <span className="form-answers__item-value">{answers.goals}</span>
        </div>
        <div className="form-answers__item">
          <span className="form-answers__item-title">
            Рекомендация о целесообразности поддержки проекта
          </span>
          <span className="form-answers__item-value">
            {answers.recommendation}
          </span>
        </div>
      </div>
    </Card>
  );
};

export default FormAnswer;
