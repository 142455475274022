import { getExpert } from "pages/admin/api";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const ExpertAnswer = ({ record }) => {
  const [expert, setExpert] = useState({});

  useEffect(() => {
    getExpert(record.user_id)
      .then(({ data }) => {
        setExpert(data.expert);
      })
      .catch((error) => console.log(error));
  }, [record]);

  if (expert) {
    return (
      <div key={record.id} className="admin-records__item">
        <span className="admin-records__item-title">
          {expert?.first_name} {expert?.last_name}:
        </span>
        {record.answers ? (
          <a
            href={`/expert/${record.id}/answers`}
            className="admin-records__item-link"
          >
            <Button className="button">Смотреть ответ</Button>
          </a>
        ) : (
          <span className="admin-records__item-text">Нeт ответа</span>
        )}
      </div>
    );
  }
};

export default ExpertAnswer;
