import React, { useEffect, useState, useMemo } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import axios from "axios";
import fileDownload from "js-file-download";

export const AdminProject = () => {
  const params = useParams();
  const [grant, setGrant] = useState({});

  const getProjectData = async () => {
    axios
      .get(`/api/grant//get_grant/${params.id}`, {
        headers: { ["Content-Type"]: "application/json" },
      })
      .then(function (response) {
        setGrant(response.data[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const downloadProjectHandler = () => {
    axios
      .get(`/api/file/download/${grant.project_description}`, {
        responseType: "blob",
      })
      .then(function (response) {
        console.log(response);
        fileDownload(response.data, grant.project_description);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const downloadDiplomaHandler = () => {
    axios
      .get(`/api/file/download/${grant.lector_speciality}`, {
        responseType: "blob",
      })
      .then(function (response) {
        console.log(response);
        fileDownload(response.data, grant.lector_speciality);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const isEmptyGrant = useMemo(() => {
    for (const prop in grant) {
      if (Object.hasOwn(grant, prop)) {
        return false;
      }
    }

    return true;
  }, [grant]);

  const formatDate = useMemo(() => {
    const inputDate = new Date(grant.project_date);
    const year = inputDate.getFullYear();

    let month = inputDate.getMonth() + 1;
    let day = inputDate.getDate();

    if (day < 10) day = "0" + day;
    if (month < 10) month = "0" + month;

    const formattedToday = `${day}.${month}.${year}`;

    return formattedToday;
  }, [grant.project_date]);

  useEffect(() => {
    getProjectData();
  }, [params.id]);

  if (isEmptyGrant) {
    return (
      <div
        className="admin-page"
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 30,
          height: "20rem",
          justifyContent: "center",
          gap: 20,
        }}
      >
        <span>Загрузка данных о проекте</span>
        <Spinner style={{ marginLeft: 15 }} animation="border" role="status" />
      </div>
    );
  }

  return (
    <div className="admin-page" style={{ marginTop: 30 }}>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <h5 style={{ fontWeight: 700 }}>ДАННЫЕ ПРОЕКТА</h5>
        <h6 style={{ marginTop: 20, fontWeight: 700 }}>Название проекта</h6>
        <p style={{ fontSize: 14, fontWeight: 500, margin: 0 }}>
          {grant.project_name}
        </p>

        <h6 style={{ marginTop: 20, fontWeight: 700 }}>Дата реализации</h6>
        <p style={{ fontSize: 14, fontWeight: 500, margin: 0 }}>{formatDate}</p>

        <h6 style={{ marginTop: 20, fontWeight: 700 }}>Место реализации</h6>
        <p style={{ fontSize: 14, fontWeight: 500, margin: 0 }}>
          {grant.project_location}
        </p>

        <h6 style={{ marginTop: 20, fontWeight: 700 }}>Описание проекта</h6>
        <Button className="file-input" onClick={downloadProjectHandler}>
          Скачать файл
        </Button>

        {grant.sum == 0 && (
          <h5 style={{ marginTop: 30, fontWeight: 700 }}>ДАННЫЕ ЛЕКТОРА</h5>
        )}
        {grant.sum == 1 && <h5 style={{ marginTop: 30, fontWeight: 700 }}></h5>}

        <h6 style={{ marginTop: 20, fontWeight: 700 }}>ИНН</h6>
        <p style={{ fontSize: 14, fontWeight: 500, margin: 0 }}>
          {grant.lector_id}
        </p>

        {grant.sum == 0 && (
          <div>
            <h6 style={{ marginTop: 20, fontWeight: 700 }}>СНИЛС</h6>
            <p style={{ fontSize: 14, fontWeight: 500, margin: 0 }}>
              {grant.lector_snils}
            </p>
          </div>
        )}

        {grant.sum == 0 && grant.user_name && (
          <div>
            <h6 style={{ marginTop: 20, fontWeight: 700 }}>
              Фамилия Имя Отчество
            </h6>
            <p style={{ fontSize: 14, fontWeight: 500, margin: 0 }}>
              {grant.user_name}
            </p>
          </div>
        )}

        {grant.sum == 0 && grant.user_birthdate && (
          <div>
            <h6 style={{ marginTop: 20, fontWeight: 700 }}>Дата рождения</h6>
            <p style={{ fontSize: 14, fontWeight: 500, margin: 0 }}>
              {grant.user_birthdate}
            </p>
          </div>
        )}

        {grant.sum == 0 && (
          <div>
            <h6 style={{ marginTop: 20, fontWeight: 700 }}>
              Паспортные данные
            </h6>
            <p style={{ fontSize: 14, fontWeight: 500, margin: 0 }}>
              {grant.lector_pasport_serial} {grant.lector_pasport_number}
            </p>
          </div>
        )}

        <h6 style={{ marginTop: 20, fontWeight: 700 }}>
          Адрес электронной почты
        </h6>
        <p style={{ fontSize: 14, fontWeight: 500, margin: 0 }}>
          {grant.lector_email}
        </p>

        <h6 style={{ marginTop: 20, fontWeight: 700 }}>Телефон</h6>
        <p style={{ fontSize: 14, fontWeight: 500, margin: 0 }}>
          {grant.lector_phone_number}
        </p>

        {grant.sum == 0 && (
          <div>
            <h6 style={{ marginTop: 20, fontWeight: 700 }}>Специальность</h6>
            <Button className="file-input" onClick={downloadDiplomaHandler}>
              Скачать файл
            </Button>
          </div>
        )}

        <h5 style={{ marginTop: 30, fontWeight: 700 }}>БАНКОВСКИЕ РЕКВИЗИТЫ</h5>
        <h6 style={{ marginTop: 20, fontWeight: 700 }}>Номер счета</h6>
        <p style={{ fontSize: 14, fontWeight: 500, margin: 0 }}>
          {grant.contract_bill_number}
        </p>

        <h6 style={{ marginTop: 20, fontWeight: 700 }}>БИК</h6>
        <p style={{ fontSize: 14, fontWeight: 500, margin: 0 }}>
          {grant.contract_bank_bik}
        </p>
      </div>
      {/* <Button style={{ marginTop: 30 }} className='button'>Сохранить</Button> */}
    </div>
  );
};
