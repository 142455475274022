import React, { useEffect, useState } from 'react';
import { Button } from "react-bootstrap";
import { useParams } from "react-router";
import axios from "axios";
import fileDownload from "js-file-download";

export const UserComission = () => {
    const params = useParams();
    const [grant, setGrant] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getProjectData();
    }, [params.id])

    const getProjectData = async () => {
        axios.get(`/api/grant//get_grant/${params.id}`, { headers: { ['Content-Type']: 'application/json' } })
            .then(function (response) {
                setGrant(response.data[0]);
                setLoading(!loading);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const downloadHandler = () => {
        axios.get(`/api/file/download/${grant.contest_comission_answer_file}`, { responseType: 'blob' })
            .then(function (response) {
                fileDownload(response.data, grant.contest_comission_answer_file);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    if (loading) return <div className='create-page' style={{ minHeight: 250, marginTop: 30 }}><div className='data-show' style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <h5 style={{ fontWeight: 700 }}>ОТВЕТ КОНКУРСНОЙ КОМИССИИ</h5></div><div style={{ marginTop: '5%' }} className="spinner-border text-success" role="status"></div></div>


    return (
        <div className='create-page' style={{ minHeight: 250, marginTop: 30 }}>
            <div className='data-show' style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <h5 style={{ fontWeight: 700 }}>ОТВЕТ КОНКУРСНОЙ КОМИССИИ</h5>
                {
                    grant.contest_comission_answer === 'Ожидает' && <p style={{ textAlign: 'center', marginTop: 48, marginBottom: 94 }}>Ответ ожидается</p>
                }

                {
                    grant.contest_comission_answer === 'Отклонено' && <p style={{ textAlign: 'center', marginTop: 48, marginBottom: 94 }}>Вам отказано. Просим вас проверить проект и попытаться еще раз</p>
                }
                {
                    grant.contest_comission_answer === 'Принято' && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{ marginTop: 48 }}>Одобрено комиссией</p>
                        <Button onClick={downloadHandler} style={{ marginBottom: 60 }} className='file-input'>Скачать файл</Button>
                    </div>
                }
            </div>
        </div>
    )
}
