import React from 'react'

export const Footer = () =>{


    return(
        <div>
        </div>
    )
}
