import { Card } from "react-bootstrap";
import "./styles.scss";
import { formatDate } from "utils";

const FormHead = () => {
  return (
    <Card className="form-card form-head">
      <h3 className="form-head__title">Экспертный Совет</h3>
      <p className="form-head__description">
        Поощрительной программы содействия развития профессиональных компетенций
        и организации просвещения в области медицины и экологии в рамках проекта
        БФ «ТЕФИДА» {new Date().getFullYear()}
      </p>
      {/* @TODO: Подставлять ли туда параметры */}
      <div className="form-head__info">
        <span className="form-head__info-item">Голосование</span>
        <span className="form-head__info-item">Вид: очередное</span>
        <span className="form-head__info-item">Форма: заочное голосование</span>
        <span className="form-head__info-item">
          Дата: {formatDate(new Date())}
        </span>
      </div>
    </Card>
  );
};

export default FormHead;
