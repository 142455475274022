export const options = {
  goals: [
    {
      label: "Да",
      value: 'Да',
    },
    {
      label: "Нет",
      value: "Нет",
    },
  ],

  recommendation: [
    {
      label: "За",
      value: "За",
    },
    {
      label: "Против",
      value: "Против",
    },
    {
      label: "Доработать",
      value: "Доработать",
    },
  ],
};

export const formConfig = {
  mail: {
    name: "email",
    title: 'Электронная почта *',
    placeholder: 'example@mail.com'
  },
  goals: {
    name: 'goals',
    title: 'Проект соответствуют целям и задачам Поощрительной программы. Участник проекта отвечает требованиям, предусмотренным пунктом 1.3.Положения о пожертвованиях(грантах) Поощрительной программы'
  },
  recommendation: {
    name: 'recommendation',
    title: 'Рекомендация о целесообразности поддержки проекта'
  },
  userName: {
    name: "user_name",
    title: 'Эксперт (ФИО) *',
    placeholder: 'Ваше ФИО'
  }
}