import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import axios from "axios";
import fileDownload from "js-file-download";

export const AdminComission = () => {
  const params = useParams();
  const [data, setData] = useState({
    contest_comission_answer: "",
  });

  const [file, setFile] = useState({
    contest_comission_answer_file: null,
  });

  const [initial, setInitial] = useState("");
  const [project, setProject] = useState({});

  const [isLoadingFile, setLoadingFile] = useState(false);

  const uploadFileHandler = async () => {
    console.log("выполняется");

    const formData = new FormData();
    setLoadingFile(true);

    formData.append(
      "MyFile",
      file.contest_comission_answer_file,
      file.contest_comission_answer_file.name
    );

    await axios
      .post("/api/file/upload", formData, {
        headers: { ["Content-Type"]: "application/json" },
      })
      .then(async function (response) {
        await axios
          .post(
            `/api/admin/grant_update_contest_comission_answer_file/${params.id}`,
            { contest_comission_answer_file: response.data.filename },
            { headers: { ["Content-Type"]: "application/json" } }
          )
          .then(function (response) {
            console.log(response);

            console.log("выполняется");

            setLoadingFile(false);

            getInitialData();
          })
          .catch(function (error) {
            console.log(error);

            setLoadingFile(false);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const changeHandler = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const getInitialData = async () => {
    await axios
      .get(`/api/grant/get_grant/${params.id}`, {
        headers: { ["Content-Type"]: "application/json" },
      })
      .then(function (response) {
        setProject(response.data[0]);
        setData({
          ...data,
          contest_comission_answer: response.data[0].contest_comission_answer,
        });
        setInitial(response.data[0].contest_comission_answer);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const confirmHandler = async () => {
    await axios
      .post(
        `/api/admin/grant_update_contest_comission_answer/${params.id}`,
        { ...data },
        { headers: { ["Content-Type"]: "application/json" } }
      )
      .then(function (response) {
        console.log(response);
        getInitialData();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const downloadHandler = (file) => {
    axios
      .get(`/api/file/download/${file}`, { responseType: "blob" })
      .then(function (response) {
        console.log(response);
        fileDownload(response.data, file);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onFileChange = useCallback(
    (event) => {
      setFile({
        ...file,
        contest_comission_answer_file: event.target.files[0],
      });
    },
    [file]
  );

  const renderFileInputComponent = useMemo(() => {
    if (project.contest_comission_answer_file !== "") {
      return (
        <>
          <h6 style={{ marginTop: 60, fontWeight: 700 }}>Одобрено комиссией</h6>
          <button
            style={{ marginTop: 29 }}
            className="file-input"
            name="experts_approved_1"
            onClick={() => {
              downloadHandler(project.contest_comission_answer_file);
            }}
          >
            Скачать файл
          </button>
        </>
      );
    }
    if (project.contest_comission_answer === "Принято") {
      return (
        <>
          <h6 style={{ marginTop: 60, fontWeight: 700 }}>
            Одобрено экспертами №1
          </h6>

          <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
            <label
              className="file-input"
              name="experts_approved_1"
              htmlFor="experts_approved_1"
            >
              <input
                disabled={project.contest_comission_answer !== "Принято"}
                id="experts_approved_1"
                name="expert_answer_1_file"
                onChange={onFileChange}
                type="file"
                style={{ display: "none" }}
              />
              Прикрепить файл
            </label>
            {isLoadingFile && (
              <Spinner
                animation="border"
                size="sm"
                color="green"
                role="status"
              />
            )}
          </div>
        </>
      );
    }
  }, [
    onFileChange,
    isLoadingFile,
    project.contest_comission_answer,
    project.contest_comission_answer_file,
  ]);

  useEffect(() => {
    if (data.contest_comission_answer !== initial) {
      confirmHandler();
    }
    if (file.contest_comission_answer_file !== null) {
      uploadFileHandler();
      setFile({ ...file, contest_comission_answer_file: null });
    }
    if (data.contest_comission_answer === "") {
      getInitialData();
    }
  });

  return (
    <div className="admin-page" style={{ marginTop: 30 }}>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <h5 style={{ fontWeight: 700 }}>ОТВЕТ КОНКУРСНОЙ КОМИССИИ</h5>
        <h6 style={{ marginTop: 20, fontWeight: 700 }}>Статус заявки</h6>
        <Form.Select
          name="contest_comission_answer"
          value={data.contest_comission_answer}
          onChange={changeHandler}
          aria-label="Default select example"
          style={{ borderRadius: 25 }}
        >
          <option value="Ожидает">Ожидает</option>
          <option value="Отклонено">Отклонено</option>
          <option value="Принято">Принято</option>
        </Form.Select>
      </div>
      {renderFileInputComponent}
    </div>
  );
};
