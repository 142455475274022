import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { IoMdArrowBack } from "react-icons/io";
import { Button } from "react-bootstrap";

import { FormProject, FormAnswer } from "./components";
import { getGrantData } from "../api";

import "./styles.scss";

const ExpertForm = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [grant, setGrant] = useState();
  const [expertGrant, setExpertGrant] = useState();

  const onBackNavigate = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    getGrantData(params.id)
      .then(({ data }) => {
        setGrant(data.grantInfo);

        setExpertGrant(data.grant);
      })
      .catch((error) => console.log(error));
  }, [params.id]);

  if (expertGrant && grant) {
    return (
      <div className="expert-form">
        <Button
          variant="outline-dark"
          onClick={onBackNavigate}
          className="expert-form__button"
        >
          <IoMdArrowBack />
          назад
        </Button>
        <FormProject grant={grant} />
        <FormAnswer answers={expertGrant.answers} />
      </div>
    );
  }
};

export default ExpertForm;
