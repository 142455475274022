import axios from "axios";

const defaultConfig = { headers: { "Content-Type": "application/json" } }

export const getExperts = () => {
  return axios.get(`/api/expert/experts`, defaultConfig)
}

export const getExpert = (id) => {
  return axios.get(`/api/expert/expert/${id}`, defaultConfig)
}

export const updateExpert = (id, data) => {
  return axios.post(`/api/expert/expert_update/${id}`, { data }, defaultConfig)
}