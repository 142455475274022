import { Card } from "react-bootstrap";
import { formatDate } from "utils";

const FormProject = ({ grant }) => {
  console.log(grant);

  return (
    <Card className="form-card">
      <p className="form-card__text">
        {`Заявка, поступившая в рамках благотворительной программы ${
          grant?.user_name
        }, название мероприятия «${grant?.project_name}» от
        ${formatDate(new Date(grant?.project_date))} года`}
      </p>
    </Card>
  );
};

export default FormProject;
