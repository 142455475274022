import { useEffect, useMemo, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { getExperts } from "../api";
import { NavLink } from "react-router-dom";

const ExpertsShow = () => {
  const [experts, setExperts] = useState([]);

  const renderExperts = useMemo(() => {
    return experts.map((expert) => {
      return (
        <tr>
          <td>{expert.user_email}</td>
          <td>{expert.first_name}</td>
          <td>{expert.last_name}</td>
          <td style={{ verticalAlign: "middle", textAlign: "center" }}>
            <NavLink to={`/expert/${expert.id}/edit`}>
              <Button>редактировать</Button>
            </NavLink>
          </td>
        </tr>
      );
    });
  }, [experts]);

  useEffect(() => {
    getExperts()
      .then(({ data }) => setExperts(data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="admin-table">
      <Table className="admin-table__list" bordered hover>
        <thead>
          <tr>
            <th colSpan="5">Эксперты</th>
          </tr>
          <tr>
            <th>Email</th>
            <th>Фамилия</th>
            <th>Имя Отчество</th>
            <th>Управление</th>
          </tr>
        </thead>
        <tbody>{renderExperts}</tbody>
      </Table>
    </div>
  );
};

export default ExpertsShow;
