import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { IoMdArrowBack } from "react-icons/io";
import { Button } from "react-bootstrap";

import {
  FormHead,
  FormInfo,
  FormRadio,
  FormInput,
  FormProject,
} from "./components";

import { getGrantData, setGrantAnswers } from "../api";
import { formConfig, options } from "./config";

import "./styles.scss";

const ExpertForm = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [grant, setGrant] = useState({});
  const [expert, setExpert] = useState();
  const [isValid, setIsValid] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    goals: "",
    recommendation: "",
    user_name: expert?.first_name || "",
  });

  const onBackNavigate = useCallback(() => {
    const id = params.id;

    navigate(`/expert/${id}/grant`);
  }, [navigate, params]);

  const onSetFormField = useCallback(
    (name, value) => {
      setFormData({ ...formData, [name]: value });
    },
    [formData]
  );

  const onSendForm = useCallback(() => {
    setGrantAnswers(params.id, formData)
      .then((response) => {
        console.log(response);

        navigate("/");
      })
      .catch((error) => console.log(error));
  }, [params.id, formData, navigate]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userData")).data;

    setFormData({
      ...formData,
      email: user.user_email,
      user_name: `${user.first_name} ${user.last_name}`,
    });

    setExpert(user);
  }, []);

  useEffect(() => {
    getGrantData(params.id)
      .then(({ data }) => {
        setGrant(data.grantInfo);
      })
      .catch((error) => console.log(error));
  }, [params.id]);

  useEffect(() => {
    const isEmpty = Object.values(formData).some(
      (x) => x === null || x === "" || x === undefined
    );

    if (!isEmpty) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formData, isValid]);

  if (expert && grant) {
    return (
      <div className="expert-form">
        <Button
          variant="outline-dark"
          onClick={onBackNavigate}
          className="expert-form__button"
        >
          <IoMdArrowBack />
          назад
        </Button>
        <FormHead />
        <FormInput
          value={expert?.user_email}
          name={formConfig.mail.name}
          title={formConfig.mail.title}
          onSetFormField={onSetFormField}
          placeholder={formConfig.mail.placeholder}
        />
        <FormInfo />
        <FormProject grant={grant} />
        <FormRadio
          formData={formData}
          options={options.goals}
          setFormData={onSetFormField}
          name={formConfig.goals.name}
          description={formConfig.goals.title}
        />
        <FormRadio
          setFormData={onSetFormField}
          options={options.recommendation}
          name={formConfig.recommendation.name}
          description={formConfig.recommendation.title}
        />
        <FormInput
          name={formConfig.userName.name}
          onSetFormField={onSetFormField}
          title={formConfig.userName.title}
          placeholder={formConfig.userName.placeholder}
          value={`${expert?.first_name} ${expert?.last_name}`}
        />

        <Button disabled={!isValid} onClick={onSendForm}>
          Отправить
        </Button>
      </div>
    );
  }
};

export default ExpertForm;
