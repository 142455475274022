import React, { useState, useContext } from "react";
import { Button, Form, FormControl, InputGroup, Modal } from "react-bootstrap";
import axios from "axios";
import fileDownload from "js-file-download";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

export const Main = () => {
  const history = useNavigate();
  const auth = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [politics, setPolitics] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    axios
      .post("/api/auth/esia", { status: "ok" })
      .then(function (response) {
        // auth.login(response.data.token, response.data.userId, response.data.is_admin);
        // setShow(false);
        // history('/authed');
        // window.location.reload();
        window.location.href = response.data.url;
      })
      .catch(function (error) {
        console.log(error);
        setError(true);
      });
  };
  const [error, setError] = useState(false);

  const [user_data, setUserData] = useState({
    user_email: "",
    user_password: "",
  });

  const handleSubmit = () => {
    axios
      .post("/api/auth/login", user_data)
      .then(function (response) {
        auth.login(
          response.data.token,
          response.data.data,
          response.data.is_admin
        );
        setShow(false);
        history("/authed");
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        setError(true);
      });
  };

  const changeHandler = (event) => {
    setUserData({ ...user_data, [event.target.name]: event.target.value });
    setError(false);
  };

  const download = () => {
    axios
      .get(`/api/auth/get_politics`, { responseType: "blob" })
      .then(function (response) {
        console.log(response);
        fileDownload(response.data, "politics.pdf");
      })
      .catch(function (error) {
        console.log(error);
        setError(true);
      });
  };

  const downloadPosition = () => {
    axios
      .get(`/api/auth/get_position`, { responseType: "blob" })
      .then(function (response) {
        fileDownload(response.data, "position.pdf");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="main">
      <h1>ГРАНТЫ ФОНДА</h1>
      <h5>Поддержка научных проектов</h5>
      <h5>
        Ознакомьтесь с{" "}
        <span
          onClick={downloadPosition}
          style={{ color: "blue", cursor: "pointer" }}
        >
          положением
        </span>{" "}
        и войдите в личный кабинет
      </h5>
      <Button
        style={{ marginTop: 48 }}
        onClick={handleShow}
        className="button-big"
      >
        ВХОД ЧЕРЕЗ ГОСУСЛУГИ
      </Button>
      {process.env.NODE_ENV !== "production" && (
        <Button
          style={{ marginTop: 48 }}
          className="button-big"
          variant="primary"
          onClick={() => setShow(true)}
        >
          Войти по e-mail и паролю
        </Button>
      )}
      <p>
        Выполняя вход в кабинет, вы соглашаетесь с{" "}
        <span onClick={download} style={{ color: "blue" }}>
          Положением об обработке персональных данных
        </span>
        .
      </p>

      {process.env.NODE_ENV !== "production" && (
        <Modal
          dialogClassName="modal-10w"
          className="modal"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Авторизация</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className={error ? "error" : "error-hidden"}>
              * Неверный e-mail или пароль
            </p>
            <Form.Label style={{ marginTop: 10 }} htmlFor="basic-url">
              Введите e-mail
            </Form.Label>
            <InputGroup className="input-modal">
              <FormControl
                type="text"
                name="user_email"
                value={user_data.email}
                onChange={changeHandler}
                style={{ borderRadius: 20 }}
                id="basic-url"
                aria-describedby="basic-addon3"
              />
            </InputGroup>
            <Form.Label style={{ marginTop: 10 }} htmlFor="basic-url">
              Введите пароль
            </Form.Label>
            <InputGroup className="input-modal">
              <FormControl
                type="password"
                name="user_password"
                value={user_data.password}
                onChange={changeHandler}
                style={{ borderRadius: 20 }}
                id="basic-url"
                aria-describedby="basic-addon3"
              />
            </InputGroup>
            <InputGroup
              style={{ marginTop: 10, display: "flex", alignItems: "center" }}
            >
              <Form.Check
                style={{ marginRight: 5 }}
                onChange={() => {
                  setPolitics(!politics);
                }}
                type={"checkbox"}
                id="checkbox"
              />
              <span className="modal-span">
                Ознакомлен с{" "}
                <span className="download-span" onClick={download}>
                  политикой
                </span>{" "}
                конфиденциальности
              </span>
            </InputGroup>
          </Modal.Body>
          <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
            <Button
              disabled={!politics}
              className="button"
              variant="primary"
              onClick={handleSubmit}
            >
              Войти
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
