import React, {useContext} from 'react';
import logo from '../../images/logo.svg';
import logo_text from '../../images/logo_text.svg';
import exit from '../../images/utils/exit.png';
import {Button} from "react-bootstrap";
import {AuthContext} from "../../context/AuthContext";
import {useAuth} from "../../hooks/auth.hook";

export const Header = () =>{
    const {token} = useAuth()
    const isAuthenticated = !!token;
    const auth = useContext(AuthContext);
    const logoutHandler = () => {
        auth.logout();
        window.location.reload();
    }

    return(
        <div className='header'>
            <div style={{display:'flex', alignItems:'center'}}>
                <img className='header-logo' src={logo} alt=''/>
                <img className='header-logo-text' src={logo_text} alt=''/>
                <p>8 999 333 92 07</p>
            </div>
            {isAuthenticated ? ( <Button onClick={logoutHandler} className='exit-button'>ВЫХОД <img src={exit} alt=''/></Button>):(<></>)}
        </div>
    )
}
