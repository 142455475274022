import { useState, useCallback, useEffect } from 'react'

const storageName = 'userData'

export const useAuth = () => {
    const [token, setToken] = useState(null);
    const [data, setData] = useState(null);
    const [admin, setAdmin] = useState(null);
    const [ready, setReady] = useState(false);
    const [expert, setExpert] = useState(false)

    const login = useCallback((jwtToken, data, is_admin, is_expert) => {
        setToken(jwtToken);
        setData(data);
        setAdmin(is_admin);
        setExpert(is_expert)

        localStorage.setItem(storageName, JSON.stringify({
            token: jwtToken,
            data: data,
            isAdmin: is_admin,
            isExpert: is_expert
        }))
    }, [])


    const logout = useCallback(() => {
        setToken(null);
        setData(null);
        setAdmin(null);
        setExpert(null)


        localStorage.removeItem(storageName)
    }, [])

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem(storageName))

        if (data && data.token) {
            login(data.token, data.data, data.isAdmin, data.isExpert);
        }

        setReady(true)

    }, [login])


    return { login, logout, token, admin, data, expert, ready }
}
