import { useCallback, useMemo } from "react";
import { FILTER_MODS } from "../../config";
import "./styles.scss";

const ATTRIBUTES = {
  filterMode: "data-mode",
};

const Controls = ({ onFilterChange, filterMode }) => {
  const onChangeFilterMode = useCallback(
    (event) => {
      const targetMode = event.target.getAttribute(ATTRIBUTES.filterMode);

      if (targetMode) {
        onFilterChange(targetMode);
      }
    },
    [onFilterChange]
  );

  const renderButtons = useMemo(() => {
    const buttons = [
      {
        id: "filter-button-1",
        title: "Все заявки",
        filterMode: FILTER_MODS.all,
      },
      {
        id: "filter-button-2",
        title: "Без ответа",
        filterMode: FILTER_MODS.unanswered,
      },
      {
        id: "filter-button-3",
        title: "С ответом",
        filterMode: FILTER_MODS.answered,
      },
    ];

    return buttons.map((button) => {
      const isActive =
        button.filterMode === filterMode
          ? "expert-controls__button_active"
          : "";
      const classNames = `expert-controls__button ${isActive} `;

      return (
        <button
          key={button.id}
          data-mode={button.filterMode}
          onClick={onChangeFilterMode}
          className={classNames}
        >
          {button.title}
        </button>
      );
    });
  }, [onChangeFilterMode, filterMode]);

  return <div className="expert-controls">{renderButtons}</div>;
};

export default Controls;
